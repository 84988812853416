import { GridOptions } from 'ag-grid-community';
import {
  BreakPointNumericCell,
  InputCell,
  NumericCell,
  SelectRateTypesCell,
  SelectServiceProviderDefault,
  SelectServiceProviderStatus,
} from 'components/ReactGrid/editors';
import { DeleteRow } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import {
  BreakpointRateType,
  RateMenuType,
} from 'components/Workspace/defaultConfigs/types/rates';
import useBookingRate from 'context/financials_rate/hooks';

const onCreateRateGroupMapping = response => {
  if (response?.data?.addRateRateGroupMapping) {
    const { rateGroupMapping } = response.data.addRateRateGroupMapping;
    return rateGroupMapping
      ? null
      : {
          message: 'Cannot create rate for the rate group',
        };
  }

  return {
    message: 'Cannot create rate for the rate group',
  };
};

export default function useRateLineGridConfig() {
  const {
    modifyRate,
    createRate,
    createRateRateGroupMapping,
    copyRateLine,
  } = useBookingRate();

  const rateLinesGridConfig = {
    toolbar: {
      actions: [
        {
          id: 'create',
          label: 'Add',
          tooltip: 'Add new item',
          skipPermission: true,
        },
        {
          id: 'delete',
          tooltip: 'Delete selected records',
          skipPermission: true,
        },
      ],
    },
    gridId: 'rateLine',
    modelInputField: {
      add: 'addFileStorageInput',
      modify: 'modifyFileStorageInput',
    },
    gridClassName: 'grid-container',
    gridOptions: {
      rowSelection: 'multiple',
      suppressContextMenu: true,
      enablePivot: true,
      components: {
        CustomCell,
        DeleteRow,
        InputCell,
        NumericCell,
        SelectServiceProviderStatus,
        SelectServiceProviderDefault,
        SelectRateTypesCell,
        BreakPointNumericCell,
      },
      // singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      maintainColumnOrder: true,
      editType: 'fullRow',
      defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        // suppressMenu: true,
        cellRenderer: 'CustomCell',
        gridId: 'rateLine',
        editable: true,
      },
      columnDefs: [
        {
          colId: 'id',
          headerName: 'ID',
          field: 'id',
          pinned: 'left',
          width: 80,
          editable: false,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
        },
        {
          headerName: 'BreakPoint1',
          field: 'breakPoint1',
          width: 100,
          cellEditor: 'BreakPointNumericCell',
          valueGetter: params => {
            return params?.data?.breakPoint1 === 999999
              ? 'ALL ELSE'
              : `Upto (=<) ${params?.data?.breakPoint1}`;
          },
          comparator: (valueA, valueB) => {
            let result;
            try {
              const formattedValue = input => {
                if (input === 'ALL ELSE') {
                  return 999999;
                }
                const inputList = input.split(' ');
                return Number(inputList[inputList?.length - 1]);
              };

              if (formattedValue(valueA) === formattedValue(valueB)) return 0;
              result = formattedValue(valueA) > formattedValue(valueB) ? 1 : -1;
            } catch (err) {
              console.warn(err);
            }
            return result;
          },
        },
        {
          headerName: 'BreakPoint2',
          field: 'breakPoint2',
          width: 100,
          editable: false,
        },
        {
          headerName: 'Rate Type',
          field: 'rateType',
          width: 50,
          cellEditor: 'SelectRateTypesCell',
          valueGetter: params => {
            const selectedItem = BreakpointRateType?.find(
              item => item?.value === params?.data?.rateType,
            );
            return selectedItem?.label;
          },
        },
        {
          headerName: 'Charge',
          field: 'charge',
          width: 50,
          cellEditor: 'NumericCell',
          cellRendererParams: {
            type: 'currency',
          } as ICustomCellRendererParams,
          valueGetter: params => {
            return params?.data?.charge?.toFixed(2);
          },
        },
        {
          headerName: 'Cost',
          field: 'cost',
          width: 50,
          cellEditor: 'NumericCell',
          cellRendererParams: {
            type: 'currency',
          } as ICustomCellRendererParams,
          valueGetter: params => {
            return params?.data?.cost?.toFixed(2);
          },
        },
        {
          headerName: 'Action',
          colId: 'delAction',
          cellRenderer: 'DeleteRow',
          cellClass: 'cellCenter',
          editable: false,
          filter: false,
          width: 50,
          pinned: 'right',
        },
      ],
    } as GridOptions,
    hooks: {
      update: async input => {
        // 1. Update rate : * isDeleted:true, endData:current date and time
        const updateRateData = {
          id: input?.selectedRate?.id,
          endDate: new Date().toJSON(),
          isDeleted: true,
        };

        const afterUpdate = async (input, rateData) => {
          const resultCreateRate = await createRate(rateData);

          // 3. create rate lines:
          if (resultCreateRate?.data?.addRate) {
            // 3.1 if its rateGroup, create RateRateGroupMapping
            if (input?.selectedMenu?.type === RateMenuType.RATEGROUP) {
              const rateRateGroupMappingData = {
                rateId: resultCreateRate?.data?.addRate?.rate?.id,
                rateGroupId: input?.selectedMenu?.id,
              };
              const res = await createRateRateGroupMapping(
                rateRateGroupMappingData,
              );

              onCreateRateGroupMapping(res);
            }

            // 3.2. prepare rate lines
            const rateLineData = input?.currentRateLines?.nodes?.map(
              rateLine => {
                if (rateLine?.id === input?.id) {
                  return {
                    breakPoint1: input?.breakPoint1 ? input?.breakPoint1 : 0,
                    charge: input?.charge ? input?.charge : 0,
                    cost: input?.cost ? input?.cost : 0,
                    rateType: input?.rateType,
                    rateId: resultCreateRate?.data?.addRate?.rate?.id,
                  };
                }
                return {
                  breakPoint1: rateLine.breakPoint1 ? rateLine?.breakPoint1 : 0,
                  charge: rateLine?.charge ? rateLine?.charge : 0,
                  cost: rateLine?.cost ? rateLine?.cost : 0,
                  rateType: rateLine?.rateType,
                  rateId: resultCreateRate?.data?.addRate?.rate?.id,
                };
              },
            );

            // 3.3. create line lines
            const resultRateLine = await copyRateLine(rateLineData);
            if (resultRateLine?.data) {
              return {
                data: resultRateLine?.data,
                message: 'Update Rate line successfully',
              };
            }
          }
        };

        const startUpdate = async input => {
          const resultModifyRate = await modifyRate(updateRateData);
          // 2. Create rate : using the selected rate data, creates new rate
          if (resultModifyRate?.data?.modifyRate) {
            const rateData = {
              organizationId: input?.selectedRate?.organizationId,
              startDate: input?.selectedRate?.startDate,
              endDate: input?.selectedRate?.endDate,
              serviceTypeId: input?.selectedRate?.serviceTypeId,
              tonne: input?.selectedRate?.tonne
                ? input?.selectedRate?.tonne
                : 0,
              minimum: input?.selectedRate?.minimum
                ? input?.selectedRate?.minimum
                : 0,
              maximum: input?.selectedRate?.maximum
                ? input?.selectedRate?.maximum
                : 0,
              rd: input?.selectedRate?.rd ? input?.selectedRate?.rd : 0,
              rateCommodityId: input?.selectedRate?.rateCommodityId,
              detail: input?.selectedRate?.detail,
              unitOfMeasureId: input?.selectedRate?.unitOfMeasureId,
              addressFromId: input?.selectedRate?.addressFromId,
              addressToId: input?.selectedRate?.addressToId,
            };

            afterUpdate(input, rateData);
            return;
          }

          return {
            message: 'Cannot update Rate Line',
          };
        };

        startUpdate(input);
      },

      delete: async input => {
        // 1. Update rate : * isDeleted:true, endData:current date and time
        const updateRateData = {
          id: input?.selectedRate?.id,
          endDate: new Date().toJSON(),
          isDeleted: true,
        };

        const prepareRateLines = async data => {
          if (!data) return;

          const filteredRateLineData = input?.currentRateLines?.nodes?.filter(
            rateLine => !input?.deleteRateLines?.includes(rateLine?.id),
          );

          const rateLineData = filteredRateLineData.map?.(rateLine => {
            return {
              breakPoint1: rateLine.breakPoint1 ? rateLine?.breakPoint1 : 0,
              charge: rateLine?.charge ? rateLine?.charge : 0,
              cost: rateLine?.cost ? rateLine?.cost : 0,
              rateType: rateLine?.rateType,
              rateId: data?.addRate?.rate?.id,
            };
          });

          // 3.3. create line lines
          const resultRateLine = await copyRateLine(rateLineData);
          if (resultRateLine?.data) {
            return {
              data: resultRateLine?.data,
              message: 'Update Rate Line successfully',
            };
          }

          return {
            message: 'Cannot update Rate Line',
          };
        };

        async function afterDelete(input, rateData) {
          const resultCreateRate = await createRate(rateData);

          // 3. create rate lines:
          if (resultCreateRate?.data?.addRate) {
            // 3.1 if its rateGroup, create RateRateGroupMapping
            if (input?.selectedMenu?.type === RateMenuType.RATEGROUP) {
              const rateRateGroupMappingData = {
                rateId: resultCreateRate?.data?.addRate?.rate?.id,
                rateGroupId: input?.selectedMenu?.id,
              };

              const res = await createRateRateGroupMapping(
                rateRateGroupMappingData,
              );

              onCreateRateGroupMapping(res);
            }

            prepareRateLines(resultCreateRate.data);
          }
        }

        async function startDelete(input) {
          const resultModifyRate = await modifyRate(updateRateData);
          // 2. Create rate : using the selected rate data, creates new rate
          if (resultModifyRate?.data?.modifyRate) {
            const rateData = {
              organizationId: input?.selectedRate?.organizationId,
              startDate: input?.selectedRate?.startDate,
              endDate: input?.selectedRate?.endDate,
              serviceTypeId: input?.selectedRate?.serviceTypeId,
              tonne: input?.selectedRate?.tonne
                ? input?.selectedRate?.tonne
                : 0,
              minimum: input?.selectedRate?.minimum
                ? input?.selectedRate?.minimum
                : 0,
              maximum: input?.selectedRate?.maximum
                ? input?.selectedRate?.maximum
                : 0,
              rd: input?.selectedRate?.rd ? input?.selectedRate?.rd : 0,
              rateCommodityId: input?.selectedRate?.rateCommodityId,
              detail: input?.selectedRate?.detail,
              unitOfMeasureId: input?.selectedRate?.unitOfMeasureId,
              addressFromId: input?.selectedRate?.addressFromId,
              addressToId: input?.selectedRate?.addressToId,
            };

            afterDelete(input, rateData);
            return;
          }

          return {
            message: 'Cannot update rate line',
          };
        }

        startDelete(input);
      },
    },
  };

  return {
    rateLinesGridConfig,
  };
}
