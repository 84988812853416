import { IconRef } from 'components/IconSheet';
import { dateTypeSelectorOptions } from 'components/Workspace/defaultConfigs/types/common';
import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';
import { dateQueryFormatter } from 'helpers/date';
import { bookingFilterEngines } from 'helpers/filterEngines';

export const dispatchScreenConfig = {
  id: 'booking-list',
  module: ModuleTypes.Booking,
  gridIcon: IconRef.menuProducts,
  initGridHeight: 190,
  pagination: true,
  pageSize: 50,
  toolbar: {
    actions: [
      {
        id: 'create',
        label: 'Add',
        tooltip: 'Add new booking',
      },
      {
        id: 'duplicate',
        label: 'Duplicate',
        tooltip: 'Duplicate item. Select one or more items to duplicate.',
      },
      {
        id: 'export',
        label: 'Export',
        tooltip: 'Export data in the grid to Excel or CSV file',
      },
    ],
    filters: [
      {
        field: 'dateTypeSelector',
        size: 'small',
        defaultValue: 'bookedDate',
        style: {
          width: 140,
        },
        options: dateTypeSelectorOptions,
      },
      {
        field: 'dateRangePicker',
        size: 'small',
        style: {
          color: 'white',
          width: 240,
        },
      },
    ],
    jobs: [
      {
        id: 'setdates',
        label: 'Set Dates',
        tooltip: 'Set Dates',
        color: '#f6ea6d',
      },
      {
        id: 'allocate',
        label: 'Allocate',
        tooltip: 'Allocate booking',
      },
      {
        id: 'accept',
        label: 'Accept',
        tooltip: 'Accept booking',
      },
      {
        id: 'uplift',
        label: 'Uplift',
        tooltip: 'Uplift',
      },
      {
        id: 'offload',
        label: 'Offload',
        tooltip: 'Offload',
      },
      {
        id: 'unallocate',
        label: 'Unallocate',
        tooltip: 'unallocate',
      },
      {
        id: 'reset',
        label: 'Reallocate',
        tooltip: 'Reallocate',
      },
      {
        id: 'cancel',
        label: 'Cancel',
        tooltip: 'Cancel',
        color: '#FF0C0C',
      },
    ],
  },
  screenViews: {
    label: 'Your Views',
  },

  filterEngines: bookingFilterEngines,
  dateQueryFormatter,
  contextMenuSettings: {
    booking: [
      'allocate',
      'accept',
      'uplift',
      'offload',
      'unallocate',
      'reAllocate',
      'update',
    ],
  },
};
