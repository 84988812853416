import { IconRef } from 'components/IconSheet';
import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';
import { dateQueryFormatter } from 'helpers/date';

export const invoiceScreenConfig = {
  id: 'invoice-list',
  module: ModuleTypes.Invoices,
  gridIcon: IconRef.menuInvoice,
  initGridHeight: 170,
  pagination: true,
  pageSize: 50,

  toolbar: {
    actions: [
      {
        id: 'cancel',
        tooltip: 'Cancel invoice(s)',
        skipPermission: true,
      },
    ],
  },

  screenViews: {
    label: 'Your Views',
  },
  dateQueryFormatter,
};
