import { Col, DatePicker, Form, Input, message, Row } from 'antd';
import { SetDatesFactoryInput } from 'api/graphql/generated/serviceTypesAndHooks';
import Modal from 'components/Modal/index';
import { BookingPlaceTypes } from 'components/Workspace/defaultConfigs/types/booking';
import DateFormats from 'components/config/date';
import useBookings from 'context/bookings/hooks';
import React, { useEffect } from 'react';
import { StyledCancelButton, StyledSubmitButton } from '../styles';

type Props = Readonly<{
  visible: boolean;
  onClose: () => void;
}>;

function SetDatesModal(props: Props) {
  const { visible, onClose } = props;
  const [form] = Form.useForm();
  const { setDates, selectedItems } = useBookings();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const dateValues = Object.keys(values).map(key => values[key].date || null);
    const bookingIds = selectedItems?.map(item => item.id);
    const input = {
      bookingIds,
      pickupRequired: dateValues[0],
      activityRequired: dateValues[1],
      deliveryRequired: dateValues[2],
    } as SetDatesFactoryInput;

    if (
      input.pickupRequired === null &&
      input.activityRequired === null &&
      input.deliveryRequired === null
    ) {
      message.error('Please select at least one date');
      return;
    }
    setDates(input).then(res => {
      const updatedBookings = res?.data?.setDates?.bookingViews;
      if (updatedBookings?.length > 0) {
        message.success('Dates successfully set!');
        handleClose();
      } else {
        message.error('Failed to set dates.');
      }
    });
  };
  useEffect(() => {
    form.resetFields();
  }, [selectedItems]);

  return (
    <Modal
      title="Set Dates"
      width={600}
      visible={visible}
      onClose={handleClose}
      footer={false}
    >
      <Form form={form} colon={false} labelCol={{ span: 8 }}>
        <Row gutter={[12, 12]} style={{ marginBottom: 10 }}>
          <Col span={24}>
            <Form.Item label="Pickup Required">
              <Input.Group compact>
                <Form.Item name={[BookingPlaceTypes.Pickup, 'date']}>
                  <DatePicker showTime format={DateFormats.asDisplayDateTime} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Pack/Unpack Required">
              <Input.Group compact>
                <Form.Item name={[BookingPlaceTypes.Activity, 'date']}>
                  <DatePicker showTime format={DateFormats.asDisplayDateTime} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Delivery Required">
              <Input.Group compact>
                <Form.Item name={[BookingPlaceTypes.Delivery, 'date']}>
                  <DatePicker showTime format={DateFormats.asDisplayDateTime} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={6}>
            <Form.Item>
              <StyledSubmitButton
                style={{ marginRight: 8, width: '35%' }}
                onClick={handleSubmit}
              >
                Save
              </StyledSubmitButton>
              <StyledCancelButton
                style={{ width: '35%' }}
                onClick={handleClose}
              >
                Cancel
              </StyledCancelButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SetDatesModal;
