import {
  CellClickedEvent,
  CellValueChangedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { message } from 'antd';
import Layout from 'components/Workspace/Layouts';
import { BookingPlaceTypes } from 'components/Workspace/defaultConfigs/types/booking';
import { defaultBookingDateFilter } from 'components/config/date';
import useApp from 'context/app/hooks';
import { ModalMode } from 'context/app/types';
import useBookings from 'context/bookings/hooks';
import useGenericCodeLists from 'context/genericCodeLists/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import Booking from '../DetailsScreen/index';
import CustomContextMenu from './components/CustomContextMenu';
import DuplicateBooking from './components/DuplicateBooking';
import Pod from './components/Pod';
import SelectVehicleGrid from './components/SelectVehicleGrid';
import SetDates from './components/SetDates';
import UpDatePortInfo from './components/UpdatePortInfo';
import { dispatchScreenConfig } from './config';
import { dispatchGridOptions } from './gridOptions';
import { getNewValueAndForceUpdate } from './helpers';

export default () => {
  // =========================== init ===================//
  useInjectReducer({
    key: sliceKey,
    reducer,
  });
  const { openDialog } = useApp();
  const dispatchRef = useRef(null);
  const dispatch = useDispatch();
  const { genericCodeListData } = useGenericCodeLists();
  const {
    bookingWorkspaceData,
    bookingDetails,
    selectedItems,
    flagRows,
    subModals,
    getBookingList,
    cancelBooking,
    refreshWorkspaceData,
    resetItemDetails,
    getBookingById,
    setSelectedItems,
    modifyBooking,
    allocateBooking,
    reAllocateBooking,
    setUpdatedItem,
    showSubModal,
    pODBooking,
  } = useBookings();

  const { currentAccount } = useAuthentication();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState(false);

  // = ========================== handler ===================//
  const handleCellValueChanged = async (event: CellValueChangedEvent) => {
    const { data, column, value } = event;
    const colId = column.getId();
    const bookingId = data?.id;
    const schedulePriority =
      data?.schedulePriority === 0 ? 0 : Number(data?.schedulePriority) || null;

    const newColIds = [
      { old: 'bookingFlag', new: 'flags' },
      { old: 'memo', new: 'dispatchMemo' },
    ];
    const newColId = newColIds.find(({ old }) => old === colId)?.new || colId;

    const bookingData = {
      id: bookingId,
      currentVehicleAssetId: data?.currentVehicleAssetId,
      [newColId]: value,
      schedulePriority,
    };
    modifyBooking([bookingData]);
  };

  const handleCellClicked = async (event: CellClickedEvent) => {
    setShowContextMenu(false);
    const { data, column, value, api, node } = event;
    if (api.isToolPanelShowing()) {
      // if the toolpanel of sidebar on grid, close the tool panel
      api.closeToolPanel();
    }
    const colId = column.getId();
    const preventColumns = [
      'status',
      'shift',
      'dispatchMemo',
      'schedulePriority',
      'bookingFlags',
    ];
    if (preventColumns.includes(colId)) {
      node.setSelected(false);
      if (data?.status === 'DEL' && column.getColId() === 'status') {
        showPodSubModal(data);
      }

      const bookingId = data?.id;
      const { newValue, forceUpdate, newColId } = getNewValueAndForceUpdate(
        colId,
        data,
        value,
      );

      if (newValue !== value && forceUpdate) {
        const { data } = node;
        let updateData: any = {
          [newColId]: newValue,
        };

        const schedulePriority = data?.schedulePriority;

        modifyBooking({
          id: bookingId,
          currentVehicleAssetId: data?.currentVehicleAssetId,
          schedulePriority,
          ...updateData,
        });
      }
    }
  };

  function showPodSubModal(data) {
    showSubModal({
      name: 'pod',
      visible: true,
      content: {
        id: data?.id,
        docket: data?.docket,
        client: data?.organizationFreightPayer?.name,
        actualDelivery: data?.bookingPlaces?.find(
          item => item?.bookingPlaceType === BookingPlaceTypes.Delivery,
        ),
      },
    });
  }

  const handleNextPlaceAllocateBooking = () => {
    if (selectedVehicle) {
      const bookingIds = selectedItems?.map(item => item.id);
      allocateBooking(bookingIds, selectedVehicle).then(res => {
        if (res?.data?.allocateBookings?.bookingViews?.length > 0) {
          setUpdatedItem(res?.data?.allocateBookings?.bookingViews);
          message.success('Allocate bookings successfully!');
          handleCloseSubModal('selectVehicle');
        } else {
          message.error('Cannot allocate bookings');
        }
      });
    } else {
      message.error('Please select a vehicle');
    }
  };

  const handleNextPlaceReAllocateBooking = () => {
    const bookingIds = selectedItems?.map(item => item.id);
    reAllocateBooking(bookingIds, selectedVehicle).then(res => {
      if (res?.data?.reallocateBookings?.bookingViews?.length > 0) {
        setUpdatedItem(res?.data?.reallocateBookings?.bookingViews);
        message.success('ReAllocate bookings successfully!');
        handleCloseSubModal('reSelectVehicle');
      } else {
        message.error('Cannot ReAllocate bookings');
      }
    });
  };

  const handleAcceptPOD = async input => {
    const result = await pODBooking(input);
    if (
      result?.data?.pODBooking?.dispatchOutput?.validBookingViews?.length > 0
    ) {
      handleCloseSubModal('pod');
      message.success('POD booking Successfully');
      const newData =
        result?.data?.pODBooking?.dispatchOutput?.validBookingViews[0];
      setUpdatedItem([newData]);
    } else {
      message.error('Cannot POD booking');
    }
  };

  const handleCloseSubModal = name => {
    showSubModal({ name, visible: false });
  };

  const handleCellContextMenu = (params: any) => {
    const { clientX, clientY } = params.event;
    setContextMenuPosition({ x: clientX, y: clientY });
    setShowContextMenu(true);
  };

  const _viewBookingRecord = (data: any) => {
    getBookingById(data?.id);
  };

  const handleDuplicate = () => {
    const selectedBooking = selectedItems?.[0];
    openDialog({
      content: <DuplicateBooking selectedBooking={selectedBooking} />,
      dialogProps: {
        height: '200px',
        width: '300px',
      },
    });
  };

  const handleCancelBooking = async id => {
    let message = 'Cannot cancel booking';
    const result = await cancelBooking(id);
    if (result?.data?.cancelBookings?.bookingViews.length > 0) {
      message = 'Cancel booking successfully!';
    }
    return {
      message,
      data: result?.data?.cancelBookings?.bookingViews || [],
    };
  };

  const gridOptions = {
    ...dispatchGridOptions,
    context: {
      handleAttention: (event, flag) => {
        handleCellValueChanged({ ...event, value: flag });
      },
    },
    getContextMenuItems: () => {
      return [];
    },
    onCellContextMenu: params => {
      handleCellContextMenu(params);
    },
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (selectedRows.find(row => row.id === rowNode.data.id))
          records.push(rowNode.data);
      });
      setSelectedItems([...records]);
    },

    onCellValueChanged: (event: CellValueChangedEvent) => {
      handleCellValueChanged(event);
    },

    onCellClicked: (event: CellClickedEvent) => {
      handleCellClicked(event);
    },
  };

  // =========================== useEffect ===================//

  useEffect(() => {
    const defaultDivisionIds = currentAccount?.userDivisionMappings?.map(
      d => d.divisionId,
    );
    let newConfig = {
      ...dispatchScreenConfig,
    };
    const customerTypeId = genericCodeListData?.OrganizationType?.find(
      el => el?.name === 'Customer',
    )?.id; // Get the Id of organization type "customer"

    if (currentAccount?.organizationTypeId === customerTypeId) {
      // If the current account type is "customer", change label to customer label ("Booking List")
      newConfig = {
        ...newConfig,
        module: {
          ...dispatchScreenConfig?.module,
          label: dispatchScreenConfig?.module?.customerLabel,
        },
      };
    }

    dispatch(
      actions.setWorkspace({
        query: {
          divisionIds: defaultDivisionIds?.length ? defaultDivisionIds : null,
          cursor: null,
          filters: {
            and: [
              {
                bookedDate: {
                  gte: defaultBookingDateFilter.from,
                },
              },
              {
                bookedDate: {
                  lte: defaultBookingDateFilter.to,
                },
              },
            ],
          },
          order: { id: 'DESC' },
        },
        workspace: { ...newConfig, gridOptions },
      }),
    );
  }, []);

  useEffect(() => {
    const handleContextMenu = (event: Event) => {
      if (!dispatchRef.current?.contains(event.target)) {
        return; // If the click is outside the grid, show the default context menu
      }
      event.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    if (bookingDetails) {
      openDialog({
        content: (
          <Booking
            bookingType={bookingDetails?.bookingType?.name}
            mode={ModalMode.MODIFY}
            status={bookingDetails?.bookingLegEventStatus?.code}
            record={bookingDetails}
          />
        ),
        dialogProps: {
          height: 90,
        },
      });
    }
    return () => {
      resetItemDetails();
    };
  }, [bookingDetails]);

  // = ========================== render components ===================//

  const renderSubModal = () => {
    if (subModals) {
      return (
        <>
          {Object.keys(subModals)?.map(item => (
            <>
              {
                {
                  setDates: (
                    <SetDates
                      visible={subModals[item].visible}
                      onClose={() => handleCloseSubModal(item)}
                    />
                  ),
                  selectVehicle: (
                    <SelectVehicleGrid
                      visible={subModals[item].visible}
                      onNextPlace={handleNextPlaceAllocateBooking}
                      onSelectVehicle={setSelectedVehicle}
                      onClose={() => handleCloseSubModal(item)}
                      title="Allocate Booking"
                    />
                  ),
                  reSelectVehicle: (
                    <SelectVehicleGrid
                      visible={subModals[item].visible}
                      onNextPlace={handleNextPlaceReAllocateBooking}
                      onSelectVehicle={setSelectedVehicle}
                      onClose={() => handleCloseSubModal(item)}
                      title="ReAllocate Booking"
                    />
                  ),
                  upDatePortInfo: (
                    <UpDatePortInfo
                      onClose={() => handleCloseSubModal(item)}
                      visible={subModals[item].visible}
                    />
                  ),
                  pod: (
                    <Pod
                      visible={subModals[item].visible}
                      onClose={() => handleCloseSubModal(item)}
                      title="POD Booking"
                      content={subModals?.pod?.content}
                      onConfirm={handleAcceptPOD}
                    />
                  ),
                }[item]
              }
            </>
          ))}
        </>
      );
    }
    return null;
  };

  // = ========================== main render ===================//

  return (
    <div ref={dispatchRef}>
      <Layout
        gridOptions={gridOptions}
        data={bookingWorkspaceData}
        flagRows={flagRows}
        selectedItems={selectedItems}
        onGetDataList={getBookingList}
        onRefreshDataList={refreshWorkspaceData}
        onViewRecord={_viewBookingRecord}
        onDuplicate={handleDuplicate}
        onDelete={handleCancelBooking}
      />

      {showContextMenu && (
        <CustomContextMenu
          x={contextMenuPosition.x}
          y={contextMenuPosition.y}
          onClose={() => setShowContextMenu(false)}
        />
      )}
      {renderSubModal()}
    </div>
  );
};
