import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoComplete, AutoCompleteProps, Input } from 'antd';
import { useGetOrgOrgMappingsLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import useAuthentication from 'context/security_authentication/hook';
import WithFormHooksController from 'components/common/WithFormHooksController';
import Spinner from 'components/Loaders/Spinner';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import useUsersStore from 'containers/UsersAndGroups/Users/store';
import { getFormattedOptions, getQueryVariables } from './helpers';

type Props = Readonly<{
  name?: string;
  control: Control;
  filters?: string;
  orgMappingId?: number;
  orgDisplayName?: string;
  componentProps?: Partial<AutoCompleteProps>;
}>;

export default function FormControlledOrgAutoComplete({
  name = 'organisation',
  orgDisplayName,
  orgMappingId,
  control,
  componentProps,
}: Props) {
  const [options, setOptions] = useState([]);
  const { currentAccount } = useAuthentication();

  const setSelectedOrganizationId = useUsersStore(
    state => state.setSelectedOrganizationId,
  );

  const [getOrganisations, { loading }] = useGetOrgOrgMappingsLazyQuery({
    onCompleted: res => {
      if (res?.organizationOrganizationMappings?.nodes) {
        getFormattedOptions(
          res.organizationOrganizationMappings.nodes,
        ).then(opts => setOptions(opts));
      }
    },
  });

  useEffect(() => {
    getOrganisations({
      // @ts-ignore
      variables: getQueryVariables(currentAccount),
    });

    if (orgMappingId) {
      setSelectedOrganizationId(orgMappingId);
    }
  }, []);

  const _debouncedUpdate = (value: string = '') => {
    if (!value) {
      setOptions([]);
    }

    getOrganisations({
      // @ts-ignore
      variables: getQueryVariables(currentAccount, value),
    });
  };

  function _onSelect(_val, option) {
    const org = options?.find(o => o.id === option.id);

    if (org?.id) {
      setSelectedOrganizationId(org.id);
    }
  }

  return (
    <WithFormHooksController
      control={control}
      name={name}
      component={
        <AutoComplete
          {...componentProps}
          options={options}
          defaultValue={orgDisplayName}
          onSearch={debounce(_debouncedUpdate, 400)}
          onSelect={_onSelect}
          filterOption={(input, option) =>
            option?.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          <Input
            placeholder={`${loading ? '...' : 'type organisation'}`}
            className="px-2 rounded-md"
            prefix={
              loading ? (
                <span>
                  <Spinner />
                </span>
              ) : (
                <FontAwesomeIcon
                  icon={faUsers}
                  style={{
                    fontSize: '12px',
                    color: 'gray',
                    marginRight: '4px',
                    opacity: 0.3,
                  }}
                />
              )
            }
          />
        </AutoComplete>
      }
    />
  );
}
