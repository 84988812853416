export enum containerTypes {
  'GP (General Purpose)' = 12,
  'HC (HI Cube)' = 13,
  'RE (Reefer - Operating)' = 14,
  'RX (Reefer - Non-operating)' = 15,
  'B (Bulk)' = 16,
  'I (Insulated)' = 17,
  'T (Tank)' = 18,
  'S (Shippers)' = 19,
  'FR (Flat Rack)' = 20,
  'P (Platform)' = 21,
  'V (Ventilated' = 22,
  'OT (Open Top)' = 23,
}

export enum jobTypes {
  'IMP' = 3,
  'EXP' = 4,
  'MOV' = 5,
}

export enum containerLoadTypes {
  REAR = 26,
  SIDE = 27,
}

export enum containerPorts {
  NZAKL = 33,
  NZTRG = 34,
  NZLYT = 35,
  NZTIU = 36,
}

export enum fullEmptyTypes {
  'F' = 6,
  'M' = 7,
}
