import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'antd';
import {
  ImpedimentFilterInput,
  useGetImpedimentsLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import usePredefinedData from 'context/predefined_data/hooks';
import { debounce } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

interface ISelectCellParams extends ICellEditorParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  const [value, setValue] = useState(props.value);
  const { impediments } = usePredefinedData();
  const [options, setOptions] = useState(null);
  const [impedimentList, setImpedimentList] = useState(null);

  const refineOptions = dataList => {
    setImpedimentList(dataList);
    const listOptions = dataList?.map(dataItem => {
      if (dataItem?.id) {
        return {
          value: dataItem.id,
          label: dataItem?.name || '',
        };
      }
      return undefined;
    });
    setOptions(listOptions);
  };

  const handleSearch = value => {
    if (!value) {
      setOptions([]);
      return false;
    }
    const variables = {
      filters: {
        name: { contains: value },
        isDeleted: { neq: true },
      } as ImpedimentFilterInput,
      cursor: null,
      order: { id: 'DESC' },
    };

    getImpedimentGoods(variables);
    return true;
  };

  const handleOnChange = value => {
    setValue(value);
    const seletedItem = options?.find(item => item.value === value);
    if (seletedItem) {
      props.parseValue(seletedItem);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const valueFormat = impedimentList?.find(item => item.id === value);
        if (valueFormat) {
          return valueFormat;
        }
        return props.value;
      },
      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  // ================================ data process ======================================//

  const handleImpedimentsData = response => {
    if (response?.impediments?.nodes) {
      setImpedimentList(response?.impediments?.nodes);
      const listOptions = response.impediments.nodes.map(dataItem => {
        if (dataItem?.id) {
          return {
            value: dataItem.id,
            label: dataItem.name,
          };
        }
        return undefined;
      });
      setOptions(listOptions);
    }
  };

  const [getImpedimentLazyQuery] = useGetImpedimentsLazyQuery({
    onCompleted: handleImpedimentsData,
  });

  const getImpedimentGoods = variables => {
    getImpedimentLazyQuery({
      variables,
    });
  };

  useEffect(() => {
    if (impediments) {
      refineOptions(impediments);
    }
  }, [impediments]);

  return (
    <>
      {!props.node.rowPinned ? (
        <Select
          style={{ width: '100%' }}
          value={value}
          onSearch={debounce(handleSearch, 400)}
          onChange={handleOnChange}
          bordered={false}
          allowClear
          options={options}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        />
      ) : (
        <span>{props.value}</span>
      )}
    </>
  );
});
