import { GridOptions } from 'ag-grid-community';
import {
  DateTimePickerCell,
  InputCell,
  NumericCell,
  RateAddressFromAutoCompleteCell,
  RateAddressToAutoCompleteCell,
  RateRateCommodityAutoCompleteCell,
  SelectServiceTypeCell,
  SelectUnitOfMeasuerTypeCell,
} from 'components/ReactGrid/editors';
import { DeleteRow } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import CustomTooltipRateCommodity from 'components/ReactGrid/renderers/CustomTooltipRateCommodity';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import {
  GenericCodeBookingServiceType,
  UnitOfMeasureTypeOptions,
} from 'components/Workspace/defaultConfigs/types/common';
import useBookingRate from 'context/financials_rate/hooks';
import { RateMenuType } from '../../../../components/Workspace/defaultConfigs/types/rates';

export default function useRateGridConfig() {
  const {
    modifyRate,
    deleteRates,
    createRate,
    createRateRateGroupMapping,
    copyRateLine,
  } = useBookingRate();

  const ratesGridConfig = {
    toolbar: {
      actions: [
        {
          id: 'create',
          label: 'Add',
          tooltip: 'Add new item',
          skipPermission: true,
        },
        {
          id: 'delete',
          tooltip: 'Delete selected records',
          skipPermission: true,
        },
      ],
    },
    gridId: 'rate',
    gridClassName: 'grid-container',
    modelInputField: {
      add: 'addPortConnectInput',
      modify: 'modifyPortConnectInput',
    },
    gridOptions: {
      rowSelection: 'multiple',
      suppressContextMenu: true,
      enablePivot: true,
      components: {
        CustomCell,
        DeleteRow,
        InputCell,
        NumericCell,
        SelectServiceTypeCell,
        DateTimePickerCell,
        RateRateCommodityAutoCompleteCell,
        RateAddressToAutoCompleteCell,
        RateAddressFromAutoCompleteCell,
        SelectUnitOfMeasuerTypeCell,
      },
      // singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      maintainColumnOrder: true,
      editType: 'fullRow',
      defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        // suppressMenu: true,
        cellRenderer: 'CustomCell',
        gridId: 'rate',
        editable: true,
        tooltipComponent: CustomTooltipRateCommodity,
        tooltipField: 'rateCommodityId',
        tooltipComponentParams: { color: '#ececec' },
      },
      columnDefs: [
        {
          colId: 'id',
          headerName: 'ID',
          field: 'id',
          pinned: 'left',
          width: 80,
          editable: false,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
        },
        {
          headerName: 'Address From',
          field: 'addressFrom',
          pinned: 'left',
          width: 150,
          cellEditor: 'RateAddressFromAutoCompleteCell',
          valueFormatter(params) {
            if (params?.data?.addressFrom) {
              const locality =
                params?.data?.addressFrom?.addressCodeLocality?.name || '';
              const place = params?.data?.addressFrom?.addressCodePlace?.name;
              const region = params?.data?.addressFrom?.addressCodeRegion?.name;
              const countryCode =
                params?.data?.addressFrom?.addressCodeCountry?.code;
              const postCode = params?.data?.addressFrom?.postCode;
              const paramList = [
                locality,
                place,
                region,
                countryCode,
                postCode,
              ];
              let result = paramList[0];

              if (params?.data?.addressFrom?.placeType === 'country') {
                result = params?.data?.addressFrom?.country;
              } else {
                const newParamList = paramList.filter(el => el !== undefined);
                for (let i = 1; i < newParamList.length - 1; i++) {
                  result += result ? `,${newParamList[i]}` : newParamList[i];
                }
              }
              return result;
            }
            return '';
          },
          valueGetter: params => {
            if (params?.data?.addressFrom) {
              const locality =
                params?.data?.addressFrom?.addressCodeLocality?.name || '';
              const place = params?.data?.addressFrom?.addressCodePlace?.name;
              const region = params?.data?.addressFrom?.addressCodeRegion?.name;
              const countryName =
                params?.data?.addressFrom?.addressCodeCountry?.name;
              const postCode = params?.data?.addressFrom?.postCode;
              const paramList = [
                locality,
                place,
                region,
                countryName,
                postCode,
              ];
              let result = paramList[0];

              const newParamList = paramList.filter(el => el !== undefined);
              for (let i = 1; i < newParamList.length - 1; i++) {
                result += result ? `,${newParamList[i]}` : newParamList[i];
              }
              return result;
            }
            return '';
          },
        },
        {
          headerName: 'Address To',
          field: 'addressTo',
          pinned: 'left',
          width: 150,
          cellEditor: 'RateAddressToAutoCompleteCell',
          valueFormatter: params => {
            if (params?.data?.addressTo) {
              const locality =
                params?.data?.addressTo?.addressCodeLocality?.name || '';
              const place = params?.data?.addressTo?.addressCodePlace?.name;
              const region = params?.data?.addressTo?.addressCodeRegion?.name;
              const countryCode =
                params?.data?.addressTo?.addressCodeCountry?.code;
              const postCode = params?.data?.addressTo?.postCode;
              const paramList = [
                locality,
                place,
                region,
                countryCode,
                postCode,
              ];
              let result = paramList[0];

              if (params?.data?.addressTo?.placeType === 'country') {
                result = params?.data?.addressTo?.country;
              } else {
                const newParamList = paramList.filter(el => el !== undefined);
                for (let i = 1; i < newParamList.length - 1; i++) {
                  result += result ? `,${newParamList[i]}` : newParamList[i];
                }
              }

              return result;
            }
            return '';
          },
          valueGetter: params => {
            if (params?.data?.addressTo) {
              const locality =
                params?.data?.addressTo?.addressCodeLocality?.name || '';
              const place = params?.data?.addressTo?.addressCodePlace?.name;
              const region = params?.data?.addressTo?.addressCodeRegion?.name;
              const countryName =
                params?.data?.addressTo?.addressCodeCountry?.name;
              const postCode = params?.data?.addressTo?.postCode;
              const paramList = [
                locality,
                place,
                region,
                countryName,
                postCode,
              ];
              let result = paramList[0];

              const newParamList = paramList.filter(el => el !== undefined);
              for (let i = 1; i < newParamList.length - 1; i++) {
                result += result ? `,${newParamList[i]}` : newParamList[i];
              }

              return result;
            }
            return '';
          },
        },
        {
          headerName: 'Minimum',
          field: 'minimum',
          width: 50,
          cellEditor: 'NumericCell',
        },
        {
          headerName: 'Maximum',
          field: 'maximum',
          width: 50,
          cellEditor: 'NumericCell',
        },
        {
          headerName: 'UOM',
          field: 'unitOfMeasureId',
          width: 50,
          cellEditor: 'SelectUnitOfMeasuerTypeCell',
          valueGetter: params => {
            const selectedItem = UnitOfMeasureTypeOptions?.find(
              item => item?.value === params?.data?.unitOfMeasureId,
            );
            return selectedItem?.label;
          },
        },
        {
          headerName: 'Service',
          field: 'serviceTypeId',
          width: 50,
          cellEditor: 'SelectServiceTypeCell',
          valueGetter: params => {
            const selectedItem = GenericCodeBookingServiceType?.find(
              item => item?.value === params?.data?.serviceTypeId,
            );
            return selectedItem?.label;
          },
        },
        {
          headerName: 'Commodity',
          field: 'rateCommodityId',
          width: 50,
          cellEditor: 'RateRateCommodityAutoCompleteCell',
          valueGetter: params => {
            return params?.data?.rateCommodity?.name;
          },
        },
        {
          headerName: 'RD',
          field: 'rd',
          width: 50,
          cellEditor: 'NumericCell',
        },
        {
          headerName: 'Tonne',
          field: 'tonne',
          width: 50,
          cellEditor: 'NumericCell',
        },
        {
          headerName: 'Detail',
          field: 'detail',
          width: 50,
          cellEditor: 'InputCell',
        },
        {
          headerName: 'Start On',
          field: 'startDate',
          width: 50,
          cellRendererParams: {
            type: 'datetime',
          } as ICustomCellRendererParams,
          cellEditor: 'DateTimePickerCell',
        },
        {
          headerName: 'End On',
          field: 'endDate',
          width: 50,
          cellRendererParams: {
            type: 'datetime',
          } as ICustomCellRendererParams,
          cellEditor: 'DateTimePickerCell',
        },
        {
          headerName: 'Action',
          colId: 'delAction',
          cellRenderer: 'DeleteRow',
          cellClass: 'cellCenter',
          editable: false,
          filter: false,
          width: 50,
          pinned: 'right',
        },
      ],
    } as GridOptions,
    hooks: {
      // This implementation is problematic because it does a lot of things aside from update
      // i.e. create rate rate group mapping, create rate lines - should be done in backend?
      update: async input => {
        // 1. Update rate : * isDeleted:true, endData:current date and time
        const updateRateData = {
          id: input?.id,
          endDate: new Date().toJSON(),
          isDeleted: true,
        };

        const onCreateRateGroupMapping = response => {
          if (response?.data?.addRateRateGroupMapping) {
            const { rateGroupMapping } = response.data.addRateRateGroupMapping;
            return rateGroupMapping
              ? null
              : {
                  message: 'Cannot create rate for the rate group',
                };
          }

          return {
            message: 'Cannot create rate for the rate group',
          };
        };

        async function afterUpdate(rateData) {
          const resultCreateRate = await createRate(rateData);

          // 3. create rate lines
          if (resultCreateRate?.data?.addRate) {
            // 3.1 if its rateGroup, create RateRateGroupMapping
            if (input?.selectedMenu?.type === RateMenuType.RATEGROUP) {
              const rateRateGroupMappingData = {
                rateId: resultCreateRate?.data?.addRate?.rate?.id,
                rateGroupId: input?.selectedMenu?.id,
              };

              const res = await createRateRateGroupMapping(
                rateRateGroupMappingData,
              );

              onCreateRateGroupMapping(res);
            }

            // 3.2. Create rateLines : using the rateline data creates new rateLines
            const rateLineData = input?.currentRateLines?.nodes?.map(
              rateLine => {
                return {
                  breakPoint1: rateLine.breakPoint1 ? rateLine?.breakPoint1 : 0,
                  charge: rateLine?.charge ? rateLine?.charge : 0,
                  cost: rateLine?.cost ? rateLine?.cost : 0,
                  rateType: rateLine?.rateType,
                  rateId: resultCreateRate?.data?.addRate?.rate?.id,
                };
              },
            );

            const resultRateLine = await copyRateLine(rateLineData);
            if (resultRateLine?.data) {
              return {
                data: resultRateLine?.data,
                message: 'Create Rate successfully',
              };
            }
            return {
              message: 'Cannot create Rate Line',
            };
          }
        }

        async function startUpdate() {
          const resultModifyRate = await modifyRate(updateRateData);
          // 2. Create rate : using the data creates new rate
          if (resultModifyRate?.data?.modifyRate) {
            const hasNewAddressFrom = input?.editedAdressFrom?.mapId;
            const hasNewAddressTo = input?.editedAddressTo?.mapId;

            const rateData = {
              organizationId: input?.organizationId,
              startDate: input?.startDate,
              endDate: input?.endDate,
              serviceTypeId: input?.serviceTypeId,
              tonne: input?.tonne ? input?.tonne : 0,
              minimum: input?.minimum ? input?.minimum : 0,
              maximum: input?.maximum ? input?.maximum : 0,
              rd: input?.rd ? input?.rd : 0,
              rateCommodityId: input?.rateCommodityId,
              detail: input?.detail,
              unitOfMeasureId: input?.unitOfMeasureId,
              addressFromInput: input?.editedAdressFrom,
              addressToInput: input?.editedAddressTo,
              addressFromId: input?.addressFromId,
              addressToId: input?.addressToId,
            };

            if (hasNewAddressFrom) {
              delete rateData.addressFromId;
            } else {
              delete rateData.addressFromInput;
            }

            if (hasNewAddressTo) {
              delete rateData.addressToId;
            } else {
              delete rateData.addressToInput;
            }

            afterUpdate(rateData);
            return;
          }

          return {
            message: 'Cannot update Rate Line',
          };
        }

        startUpdate();
      },
      delete: async ids => {
        const resultRate = await deleteRates(ids);
        if (resultRate?.data?.deleteRates) {
          return {
            data: resultRate?.data?.deleteRates,
            message: 'Delete Rate successfully',
          };
        }
        return {
          message: 'Cannot delete Rate',
        };
      },
    },
  };
  return {
    ratesGridConfig,
  };
}
