import React from 'react';
import { Input, InputNumber } from 'antd';
import FormControlledDivisionSelect from 'components/common/FormControlledDivisionSelect';
import WithFormHooksController from 'components/common/WithFormHooksController';
import Field from 'components/Form/Field';

import { useFormHooks } from 'context/FormHooksContext';

export default function DetailsForm() {
  const { control } = useFormHooks();

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="*Vehicle Name" span={6}>
        <WithFormHooksController
          name="name"
          control={control}
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="*Division" span={6}>
        <FormControlledDivisionSelect name="divisionId" control={control} />
      </Field>
      <Field label="Description">
        <WithFormHooksController
          control={control}
          name="description"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="Code" span={6}>
        <WithFormHooksController
          control={control}
          name="code"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="Registration" span={6}>
        <WithFormHooksController
          control={control}
          name="registration"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="License" span={6}>
        <WithFormHooksController
          control={control}
          name="license"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="Make Model" span={6}>
        <WithFormHooksController
          control={control}
          name="makeModel"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="GVM" span={6}>
        <WithFormHooksController
          control={control}
          name="gvm"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="Tare" span={6}>
        <WithFormHooksController
          control={control}
          name="tare"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="Cubic" span={6}>
        <WithFormHooksController
          control={control}
          name="loadCapacityCube"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="Weight" span={6}>
        <WithFormHooksController
          control={control}
          name="loadCapacityWeight"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
    </div>
  );
}
