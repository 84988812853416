import { ICellRendererParams } from 'ag-grid-community';
import { Steps } from 'antd';
import { useGetUserNameLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import SVGIcon, { IconRef } from 'components/IconSheet';
import { BookingStatusTypes } from 'components/Workspace/defaultConfigs/types/booking';
import { serverTimeToLocalTime } from 'helpers/date';
import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

const { Step } = Steps;

const StyledFormGroup = styled.div`
  ${tw`relative w-full p-2`}
`;

const StyledDotWrapper = styled.span`
  position: relative;
  top: 15px;
  svg {
    position: absolute;
    left: -16px;
    top: -25px;
  }
`;

export interface Props extends ICellRendererParams {
  style?: {};
  field?: string;
}

export default function BookingMovesHistory(props: Props) {
  const { data } = props;
  const [userList, setUserList] = useState(null);
  const allMoves = data?.bookingLegDetails?.nodes;
  const moves = [];
  let moveTemp = [];
  allMoves.forEach(el => {
    moveTemp.push(el);
    if (el?.bookingLegEvent?.id === BookingStatusTypes.UNALL) {
      moves.push(moveTemp);
      moveTemp = [];
    }
  });
  moves.push(moveTemp);

  const [getUserQuery] = useGetUserNameLazyQuery({
    onCompleted: response => {
      setUserList(response?.users?.nodes);
    },
  });

  const getUser = filters => {
    getUserQuery({
      variables: {
        filters,
      },
    });
  };

  useEffect(() => {
    const moves = data?.bookingLegDetails?.nodes;
    const bookingLegsUserList = moves.reduce((acc, cur) => {
      if (cur?.createdBy) {
        acc?.push(cur?.createdBy);
      }
      return acc;
    }, []);

    if (bookingLegsUserList) {
      getUser({ id: { in: bookingLegsUserList } });
    }
  }, [data?.bookingLegDetails]);

  const customDot = (dot, { status, index }) => (
    <StyledDotWrapper id={`${status}-${index}`}>
      <SVGIcon scale="1.5" icon={IconRef.moves} />
      {dot}
    </StyledDotWrapper>
  );

  const renderMoves = (moves, index) => {
    return (
      <Steps
        key={index}
        current={4}
        progressDot={customDot}
        style={{ margin: 5, marginTop: 20 }}
      >
        {moves?.map(e => {
          const createdBy = userList?.find(user => user?.id === e?.createdBy);
          return (
            <Step
              key={e?.id}
              title={e?.bookingLegEvent?.code}
              description={
                <div className="details">
                  <p>{e?.bookingLegEvent?.description}</p>
                  <p>
                    {e?.createdOn
                      ? serverTimeToLocalTime(e?.createdOn, 'MM-dd-yyyy HH:mm')
                      : ''}
                  </p>
                  <p>
                    {createdBy?.userName ? `by ${createdBy?.userName}` : ''}
                  </p>
                  {e?.bookingLegEvent?.code === 'ALL'
                    ? e?.asset?.registration || data?.asset?.registration
                    : null}
                </div>
              }
            />
          );
        })}
      </Steps>
    );
  };

  return (
    <StyledFormGroup id={props.field} style={props.style}>
      {moves.map((move, index) => renderMoves(move, index))}
    </StyledFormGroup>
  );
}
