import { PortCodeTypeEnum } from 'api/graphql/generated/serviceTypesAndHooks';

export const BookingStatusTypes = {
  BOOK: 1,
  NEW: 2,
  ALL: 3,
  ACC: 4,
  PUP: 5,
  UPL: 6,
  OFL: 7,
  DEL: 8,
  PLN: 9,
  TEST: 10,
  UPK: 11,
  POD: 12,
  RDY: 13,
  HOLD: 14,
  RFP: 15,
  PAK: 16,
  UNALL: 17,
  DEH: 18,
  EXP: 19,
  CANC: 20,
  REJ: 21,
};

export const BookingPlaceTypes = {
  Pickup: 2,
  Activity: 3,
  Delivery: 4,
  Extra: 5,
};

export const BookingNextMilestoneTypesId = {
  Required: 1,
  Actual: 2,
  Available: 3,
  Other: 4,
};

export const BookingTypes = {
  GENERAL: 1,
  CONTAINER: 2,
};

export enum TBookingPlaceTypes {
  Pickup = 2,
  Activity = 3,
  Delivery = 4,
  Extra = 5,
}

export enum TBookingNextMilestoneTypes {
  Required = 1,
  Actual = 2,
  Available = 3,
  Other = 4,
}

export const BookingNextMilestoneTypes = [
  {
    value: 1,
    label: 'Required',
  },
  {
    value: 2,
    label: 'Actual',
  },
  {
    value: 3,
    label: 'Available',
  },

  {
    value: 4,
    label: 'Offload',
  },
];

export const BookingInputFields = {
  addItems: 'addBookingLineInput',
  modifyItems: 'modifyBookingLineInput',
  deleteItems: 'deleteBookingLineInput',
  addPlaces: 'addBookingPlaceInput',
  modifyPlaces: 'modifyBookingPlaceInput',
  deletePlaces: 'deleteBookingPlaceInput',
  addMilestones: 'addBookingNextMilestoneInput',
  modifyMilestones: 'modifyBookingNextMilestoneInput',
  deleteMilestones: 'deleteBookingNextMilestoneInput',
  bookingAttributes: 'bookingAttributesIds',
  addCharges: 'addBookingChargeMappingInput',
  modifyCharges: 'modifyBookingChargeMappingInput',
  deleteCharges: 'deleteBookingChargeMappingInput',
  addProducts: 'addBookingProductMappingInput',
  modifyProducts: 'modifyBookingProductMappingInput',
  deleteProducts: 'deleteBookingProductMappingInput',
  addHolds: 'addBookingImpedimentInput',
  modifyHolds: 'modifyBookingImpedimentInput',
  deleteHolds: 'deleteBookingImpedimentInput',
  addDGs: 'addDangerousGoodInput',
  modifyDGs: 'modifyDangerousGoodInput',
  deleteDGs: 'deleteDangerousGoodInput',
  addSeals: 'addBookingSealInput',
  modifySeals: 'modifyBookingSealInput',
  deleteSeals: 'deleteBookingSealInput',
};

export const containerSizes = [
  { label: '10', value: 8 },
  { label: '20', value: 9 },
  { label: '25', value: 37 },
  { label: '40', value: 10 },
  { label: '45', value: 11 },
];

export const PortCodeList = [
  {
    value: PortCodeTypeEnum.Nzakl.toString(),
    label: PortCodeTypeEnum.Nzakl.toString(),
  },
  {
    value: PortCodeTypeEnum.Nztrg.toString(),
    label: PortCodeTypeEnum.Nztrg.toString(),
  },
  {
    value: PortCodeTypeEnum.Nzlyt.toString(),
    label: PortCodeTypeEnum.Nzlyt.toString(),
  },
  {
    value: PortCodeTypeEnum.Nztiu.toString(),
    label: PortCodeTypeEnum.Nztiu.toString(),
  },
];
