import { DatePicker, InputNumber } from 'antd';
import Field from 'components/Form/Field';
import WithFormHooksController from 'components/common/WithFormHooksController';
import DateFormats from 'components/config/date';
import React from 'react';

import { useFormHooks } from 'context/FormHooksContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default function DetailsForm() {
  const { control } = useFormHooks();
  dayjs.extend(customParseFormat);

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="*Rate" span={12}>
        <WithFormHooksController
          control={control}
          name="rate"
          component={
            <InputNumber<number> addonAfter="%" className="rounded-md w-full" />
          }
        />
      </Field>
      <Field label="*Start Date" span={12}>
        <WithFormHooksController
          control={control}
          name="startDate"
          component={
            <DatePicker
              className="px-2 rounded-md"
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
              format={DateFormats.asDisplayDateTime}
            />
          }
        />
      </Field>
      <Field label="*End Date" span={12}>
        <WithFormHooksController
          control={control}
          name="endDate"
          component={
            <DatePicker
              showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
              format={DateFormats.asDisplayDateTime}
            />
          }
        />
      </Field>
    </div>
  );
}
