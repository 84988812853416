import { Divider } from 'antd';
import {
  AuthenticationUserFieldsFragment,
  OrganizationOrganizationMapping,
  OrganizationOrganizationMappingSortInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { OrganizationType } from 'components/Workspace/defaultConfigs/types/common';
import React from 'react';

export function getFormattedOptions(organisation: any): Promise<Array<any>> {
  return new Promise(resolve => {
    const options = organisation?.map(
      (dataItem: OrganizationOrganizationMapping) => {
        const isSubscriber =
          dataItem?.childOrganizationId === dataItem?.parentOrganizationId;

        const label: React.ReactNode = isSubscriber ? (
          <>
            {dataItem?.displayName || dataItem?.childOrganization?.name}
            <Divider style={{ margin: '4px 0' }} />
          </>
        ) : (
          dataItem?.displayName || dataItem?.childOrganization?.name
        );

        if (dataItem?.id) {
          return {
            id: dataItem.id,
            value: dataItem.displayName || dataItem?.childOrganization?.name,
            label,
            isSubscriber,
          };
        }
        return undefined;
      },
    );

    options.sort((a, b) => {
      if (a.isSubscriber === true) {
        return -1;
      }
      if (b.isSubscriber === true) {
        return 1;
      }
      return 0;
    });

    return resolve(options);
  });
}

export function getQueryVariables(
  currentAccount: AuthenticationUserFieldsFragment,
  value = null,
) {
  let parentOrganizationId = {};
  const displayName = value ? { displayName: { contains: value } } : {};

  if (currentAccount?.organizationOrganizationMapping.parentOrganizationId) {
    parentOrganizationId = {
      eq: currentAccount?.organizationOrganizationMapping.parentOrganizationId,
    };
  }

  if (
    currentAccount?.organizationOrganizationMapping?.organizationTypeId ===
    OrganizationType.Subscriber
  ) {
    return {
      filters: {
        parentOrganizationId,
        isDeleted: { neq: true },
        ...displayName,
      },
      cursor: null,
      order: {
        organizationTypeId: 'ASC',
      } as OrganizationOrganizationMappingSortInput,
    };
  }

  return {
    cursor: null,
    filters: {
      parentOrganizationId,
      isDeleted: { neq: true },
      ...displayName,
    },
    order: {
      id: 'DESC',
    } as OrganizationOrganizationMappingSortInput,
  };
}
