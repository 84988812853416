import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { RateMenuType } from 'components/Workspace/defaultConfigs/types/rates';
import { FormHooksProvider } from 'context/FormHooksContext';
import useBookingRate from 'context/financials_rate/hooks';
import React, { useEffect } from 'react';
import { defaultTGERateAddress } from '../helpers/payload-serializer';
import useRateStore from '../store';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';

interface IProps {
  afterValidation?: (vehicle?: any) => void;
}

export default ({ afterValidation, ...rest }: IDialogTrigger & IProps) => {
  const formTitle = 'New Rate and RateLine';
  const [
    resetFromPlace,
    resetToPlace,
    setIsSaving,
    isSaving,
  ] = useRateStore(state => [
    state.resetFromPlace,
    state.resetToPlace,
    state.setIsSaving,
    state.isSaving,
  ]);
  const {
    createRate,
    createRateLine,
    createRateRateGroupMapping,
    selectedMenu,
  } = useBookingRate();

  useEffect(() => {
    resetFromPlace();
    resetToPlace();
    setIsSaving(false);
  }, []);

  async function _onSubmit(formData: any) {
    setIsSaving(true);
    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const rateData = {
      //TGE default: this is only for TGEm we will implement settings feature to made it dynamic
      addressFromInput: defaultTGERateAddress,
      addressToInput: defaultTGERateAddress,
      //end TGE default:
      startDate: formData.startDate,
      endDate: formData.endDate,
      serviceTypeId: formData.serviceTypeId,
      tonne: formData.tonne || 0,
      minimum: formData.minimum || 0,
      maximum: formData.maximum || 0,
      rd: formData.rd || 0,
      rateCommodityId: formData.commodityId,
      detail: formData.detail,
      unitOfMeasureId: formData.unitOfMeasureId,
      organizationId: selectedMenu?.id,
    };

    if (selectedMenu?.type === RateMenuType.RATEGROUP) {
      delete rateData?.organizationId;
    }
    const qryRate = await createRate(rateData);
    const {
      data: { addRate: resultRate },
    } = qryRate;

    if (resultRate?.rate) {
      if (selectedMenu?.type === RateMenuType.RATEGROUP) {
        const rateRateGroupMappingData = {
          rateId: resultRate.rate?.id,
          rateGroupId: selectedMenu?.id,
        };
        const resultRateRateGroupMapping = await createRateRateGroupMapping(
          rateRateGroupMappingData,
        );
        if (
          !resultRateRateGroupMapping?.data?.addRateRateGroupMapping
            ?.rateGroupMapping
        ) {
          message.error('Cannot create rate for the rate group');
        }
      }

      const rateLineData = formData?.addRateLines?.map(rateLine => {
        return {
          breakPoint1: rateLine?.breakPoint1 ? rateLine?.breakPoint1 : 0,
          charge: rateLine?.charge ? rateLine?.charge : 0,
          cost: rateLine?.cost ? rateLine?.cost : 0,
          rateType: rateLine?.rateType,
          rateId: resultRate.rate?.id,
        };
      });

      const qryRateLine = await createRateLine(rateLineData);
      const {
        data: { addRateLines: resultRateLine },
      } = qryRateLine;

      if (resultRateLine?.some(el => el?.rateLine)) {
        afterValidation?.(resultRateLine);
        message.success(`Rate and rate lines created`);
      } else {
        message.error('Cannot create rate lines');
      }
    } else {
      message.error('Something went wrong ...');
    }
  }

  return (
    <FormHooksProvider resolver={schemaResolver}>
      <MainForm
        {...rest}
        title={formTitle}
        onSubmit={_onSubmit}
        isSaving={isSaving}
      />
    </FormHooksProvider>
  );
};
