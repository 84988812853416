import { Checkbox, Input, InputNumber, message } from 'antd';
import Dialog from 'components/Dialog';
import { IDialogHeader } from 'components/Dialog/types';
import Field from 'components/Form/Field';
import FormUserActions from 'components/Form/FormUserActions';
import { UnitOfMeasureTypeOptions } from 'components/Workspace/defaultConfigs/types/common';
import FormControlledSelect from 'components/common/FormControlledSelect';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { useFormHooks } from 'context/FormHooksContext';
import { getErrorMessage } from 'helpers/form-errors';
import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';

type Props = {
  surcharge?: any;
  onClose?: () => void;
  onSubmit: (data: any) => void;
} & IDialogHeader;

export default function MainForm({
  onClose,
  onSubmit,
  title,
  surcharge,
}: Props) {
  const { control } = useFormHooks();
  const formRef = useRef<HTMLFormElement>(null);
  const [isAbandoning, setIsAbandoning] = React.useState(false);
  const {
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormHooks();

  useEffect(() => {
    if (Object.keys(errors).length) {
      message.error(getErrorMessage(errors));
    }
  }, [errors]);

  async function _onSave() {
    setIsAbandoning(false);
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }

  function _onCancel() {
    if (isAbandoning || !isDirty) {
      onClose();
      return;
    }

    setIsAbandoning(true);
  }

  useEffect(() => {
    if (surcharge) {
      const fieldValues = { ...surcharge };
      Object.keys(fieldValues).forEach(fieldName => {
        setValue(fieldName, fieldValues[fieldName]);
      });
    }
    return () => {
      reset();
    };
  }, [surcharge]);

  return (
    <form
      ref={formRef}
      className="flex flex-col w-full h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col w-full h-full">
        <Dialog.Header onClose={onClose} title={title}>
          <div className="flex w-full space-x-1">
            <Controller
              control={control}
              name="applyFAF"
              render={() => (
                <Checkbox
                  checked={getValues('applyFAF')}
                  onChange={e => {
                    setValue('applyFAF', e.target.checked);
                  }}
                />
              )}
            />
            <span>Apply FAF</span>
          </div>
        </Dialog.Header>
        <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
          <Field label="Surcharge Name" span={12}>
            <WithFormHooksController
              name="name"
              control={control}
              component={<Input className="px-2 rounded-md" />}
            />
          </Field>
          <Field label="Description" span={12}>
            <WithFormHooksController
              control={control}
              name="description"
              component={<Input className="px-2 rounded-md" />}
            />
          </Field>
          <Field label="Category" span={4}>
            <WithFormHooksController
              control={control}
              name="category"
              component={<Input className="px-2 rounded-md" />}
            />
          </Field>
          <Field label="Code" span={4}>
            <WithFormHooksController
              control={control}
              name="code"
              component={<Input className="px-2 rounded-md" />}
            />
          </Field>

          <Field label="Unit of Measure" span={4}>
            <FormControlledSelect
              name="unitOfMeasureId"
              options={UnitOfMeasureTypeOptions}
            />
          </Field>

          <Field label="Cost" span={3}>
            <WithFormHooksController
              control={control}
              name="cost"
              component={
                <InputNumber className="px-2 rounded-md w-full" min={0} />
              }
            />
          </Field>
          <Field label="Cost GL Code" span={3}>
            <WithFormHooksController
              control={control}
              name="costGlcode"
              component={<Input className="px-2 rounded-md" />}
            />
          </Field>
          <Field label="Charge" span={3}>
            <WithFormHooksController
              control={control}
              name="charge"
              component={
                <InputNumber className="px-2 rounded-md w-full" min={0} />
              }
            />
          </Field>
          <Field label="Charge GL Code" span={3}>
            <WithFormHooksController
              control={control}
              name="chargeGlcode"
              component={<Input className="px-2 rounded-md" />}
            />
          </Field>
        </div>
      </div>
      <FormUserActions
        onCancel={_onCancel}
        onSave={_onSave}
        isAbandoning={isAbandoning}
        isLoading={surcharge?.isSaving}
      />
    </form>
  );
}
