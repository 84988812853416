import { IconRef } from 'components/IconSheet';
import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';

export const surchargeScreenConfig = {
  id: 'surcharge-list',
  module: ModuleTypes.Surcharge,
  gridIcon: IconRef.menuSurcharge,
  initGridHeight: 225,

  toolbar: {
    actions: [
      {
        id: 'create',
        label: 'Add',
        tooltip: 'Add new surcharge',
      },
      {
        id: 'delete',
        tooltip: 'Delete selected records',
      },
      {
        id: 'export',
        label: 'Export',
        tooltip: 'Export data in the grid to Excel or CSV file',
      },
    ],
  },
  screenViews: {
    label: 'Your Views',
  },
};
