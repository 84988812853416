export type DispatchAction =
  | 'allocate'
  | 'accept'
  | 'uplift'
  | 'offload'
  | 'reallocate'
  | 'reassign'
  | 'unallocate'
  | 'update'
  | 'cancel';

/**
 * Validates whether the given action is permitted for the given bookings.
 * @param DispatchAction 'Allocate' | 'Accept' | 'Uplift' | 'Offload'.
 * @param bookings An array of bookings to validate.
 * @returns If there are any unpermitted bookings for the given action, returns an object with an `isValid` property set to `false` and a `message` property that contains an error message. If all bookings are permitted for the given action, returns `null`.
 */
export function dispatchActionValidator(
  action: DispatchAction,
  bookings: any[],
) {
  const AllocatePermittedSourceStates = [
    'NEW',
    'UPK',
    'RDY',
    'OFL',
    'RFP',
    'PAK',
  ];
  const AcceptPermittedSourceStates = ['ALL'];
  const UpliftPermittedSourceStates = ['ALL', 'ACC'];
  const OffloadPermittedSourceStates = ['PUP', 'UPL'];
  const ReAllocatePermittedSourceStates = ['ALL', 'ACC', 'PLN'];
  const UnAllocatePermittedSourceStates = ['ALL', 'ACC', 'PLN'];

  switch (action) {
    case 'allocate': {
      const unpermittedBookings = bookings?.filter(
        booking => !AllocatePermittedSourceStates.includes(booking?.status),
      );
      if (unpermittedBookings?.length > 0) {
        const error = `The following bookings cannot be allocated: ${unpermittedBookings
          .map(booking => booking.id)
          .join(', ')}`;
        return { message: error };
      }
      return { message: null };
    }
    case 'accept': {
      const unpermittedBookings = bookings?.filter(
        booking => !AcceptPermittedSourceStates.includes(booking?.status),
      );
      if (unpermittedBookings?.length > 0) {
        const error = `The following bookings cannot be accepted: ${unpermittedBookings
          .map(booking => booking.id)
          .join(', ')}`;
        return { message: error };
      }
      return { message: null };
    }
    case 'uplift': {
      const unpermittedBookings = bookings?.filter(
        booking => !UpliftPermittedSourceStates.includes(booking?.status),
      );
      if (unpermittedBookings?.length > 0) {
        const error = `The following bookings cannot be uplifted: ${unpermittedBookings
          .map(booking => booking.id)
          .join(', ')}`;
        return { message: error };
      }
      return { message: null };
    }
    case 'offload': {
      const unpermittedBookings = bookings?.filter(
        booking => !OffloadPermittedSourceStates.includes(booking?.status),
      );
      if (unpermittedBookings?.length > 0) {
        const error = `The following bookings cannot be offloaded: ${unpermittedBookings
          .map(booking => booking.id)
          .join(', ')}`;
        return { message: error };
      }
      return { message: null };
    }
    case 'reallocate': {
      const unpermittedBookings = bookings?.filter(
        booking => !ReAllocatePermittedSourceStates.includes(booking?.status),
      );
      if (unpermittedBookings?.length > 0) {
        const error = `The following bookings cannot be reallocated: ${unpermittedBookings
          .map(booking => booking.id)
          .join(', ')}`;
        return { message: error };
      }
      return { message: null };
    }
    case 'unallocate': {
      const unpermittedBookings = bookings?.filter(
        booking => !UnAllocatePermittedSourceStates.includes(booking?.status),
      );
      if (unpermittedBookings?.length > 0) {
        const error = `The following bookings cannot be unallocated: ${unpermittedBookings
          .map(booking => booking.id)
          .join(', ')}`;
        return { message: error };
      }
      return { message: null };
    }
    case 'cancel': {
      return { message: null };
    }
    case 'update': {
      return { message: null };
    }
    default:
      return { message: `Invalid action type: ${action}` };
  }
}
