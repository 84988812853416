import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import React, { useEffect } from 'react';

import { message } from 'antd';
import { User } from 'api/graphql/generated/serviceTypesAndHooks';
import useUsers from 'context/security_users/hooks';
import { getFormOptions, getPayload } from '../../helpers';
import useUsersStore from '../../store';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';

interface IProps {
  user?: User | null;
  afterValidation?: (user?: any) => void;
}

export default ({
  user = null,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) => {
  const formHeaderSub = user ? user.userName : null;
  const formHeaderTitle = `${user ? 'Edit' : 'New'} User ${
    user ? user?.id : ''
  }`;

  const { createUser, setNewItem, setUpdatedItem, updateUser } = useUsers();
  const [selectedOrganisationId, setIsSaving, reset] = useUsersStore(state => [
    state.selectedOrganizationId,
    state.setIsSaving,
    state.reset,
  ]);

  useEffect(() => reset(), []);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSubmit(formData: any) {
    let qry;
    const isNewUser = !user;
    const payload = {
      ...getPayload(formData, user),
      organizationOrganizationMappingId: selectedOrganisationId,
    };

    setIsSaving(true);

    if (isNewUser) {
      qry = await _createUser(payload);
      setIsSaving(false);

      if (qry?.errors && qry?.errors[0]?.message) {
        const { message: errMessage } = qry.errors[0];
        const isUserExist = errMessage.includes('already');

        // assume this is an email existence error
        // will have a better way to handle this later (Error Management PR)
        message.error(
          `User ${formData.userName} create failed. ${
            isUserExist ? 'Email already in use.' : ''
          }`,
          3,
        );
        return;
      }

      message.success(`User ${formData.userName} created successfully`);
      setNewItem(qry.addUserV2);
      afterValidation?.();
    } else {
      qry = await _updateUser(payload);
      setIsSaving(false);

      if (qry?.errors && qry?.errors[0]?.message) {
        message.error(`User ${formData.userName} update failed`, 3);
        return;
      }

      message.success(`User ${formData.userName} updated`);
      setUpdatedItem(qry?.modifyUser);
      afterValidation?.();
    }
  }

  async function _createUser(payload: any): Promise<any> {
    const qry = await createUser(payload);

    if (qry?.data?.addUserV2) {
      const { addUserV2 } = qry.data;
      return Promise.resolve({ addUserV2 });
    }

    return Promise.resolve({ errors: qry.errors });
  }

  async function _updateUser(payload: any): Promise<any> {
    const qry = await updateUser(payload);
    if (qry?.data?.modifyUser) {
      const { modifyUser } = qry.data;
      return Promise.resolve({ modifyUser });
    }

    return Promise.resolve({ errors: qry.errors });
  }

  return (
    <FormHooksProvider resolver={schemaResolver} {...getFormOptions(user)}>
      <MainForm
        user={user}
        {...rest}
        onSubmit={_onSubmit}
        title={formHeaderTitle}
        subTitle={formHeaderSub}
      />
    </FormHooksProvider>
  );
};
