import { ApolloProvider } from '@apollo/client';
import WithAuthentication from 'components/WithAuthentication';
import { client } from 'config/graphql';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import 'styles/app.css';
import { GlobalStyle } from 'styles/global-styles';
import 'styles/tailwind/preflight.css';
import 'styles/tailwind/styles.css';
import { ModuleTypes } from './components/Workspace/defaultConfigs/types/modules';
import Vehicles from './containers/Assets/Vehicles/MainScreen';
import Home from './containers/Authentication/Home';
import Finance from './containers/Dashboard/Finance';
import Booking from './containers/Dispatchs/Booking';
import FafGroup from './containers/Financial/FafGroup';
import Invoices from './containers/Financial/Invoices';
import Invoicing from './containers/Financial/Invoicing';
import Rates from './containers/Financial/Rates';
import Surcharges from './containers/Financial/Surcharges';
import Places from './containers/Locations/Places';
import Customers from './containers/Organizations/Customer';
import OrganizationDivisions from './containers/Organizations/Organizations_division';
import Groups from './containers/UsersAndGroups/Groups';
import Users from './containers/UsersAndGroups/Users';

const AppWrapper = styled.div.attrs({
  className: 'h-screen w-full flex bg-mantanee-100 text-black-200',
})`
  min-height: 620px;
`;

function App() {
  return (
    <BrowserRouter>
      <AppWrapper>
        <ApolloProvider client={client}>
          <Routes>
            <Route
              path="/dashboard/*"
              element={
                <WithAuthentication module={ModuleTypes.DashboardFinance.value}>
                  <Finance />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Surcharge.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Surcharge.value}
                  screenViews
                >
                  <Surcharges />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Rate.url}
              element={
                <WithAuthentication module={ModuleTypes.Rate.value}>
                  <Rates />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Faf.url}
              element={
                <WithAuthentication module={ModuleTypes.Faf.value}>
                  <FafGroup />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Invoice.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Invoice.value}
                  screenViews
                >
                  <Invoicing />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Invoices.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Invoices.value}
                  screenViews
                >
                  <Invoices />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Booking.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Booking.value}
                  screenViews
                >
                  <Booking />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Vehicle.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Vehicle.value}
                  screenViews
                >
                  <Vehicles />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Customer.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Customer.value}
                  screenViews
                >
                  <Customers />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Division.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Division.value}
                  screenViews
                >
                  <OrganizationDivisions />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Place.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Place.value}
                  screenViews
                >
                  <Places />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.User.url}
              element={
                <WithAuthentication module={ModuleTypes.User.value} screenViews>
                  <Users />
                </WithAuthentication>
              }
            />
            <Route
              path={ModuleTypes.Group.url}
              element={
                <WithAuthentication
                  module={ModuleTypes.Group.value}
                  screenViews
                >
                  <Groups />
                </WithAuthentication>
              }
            />
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<Home />} />
          </Routes>
        </ApolloProvider>
      </AppWrapper>
      <GlobalStyle />
    </BrowserRouter>
  );
}

export default App;
