import { SelectorFloatingFilterOptions } from 'components/Workspace/defaultConfigs/types/common';
import { generalFilter, selectorFilter } from 'helpers/internalFilters';

export function generalFilterEngines(filterState) {
  let internalFilters;
  let generalFilterlList = {};
  let selectorFilterList = {};
  const selectorObjList = Object.keys(SelectorFloatingFilterOptions);
  // eslint-disable-next-line guard-for-in
  Object.keys(filterState).forEach(filter => {
    const foundSelectorFilter = selectorObjList.find(el => el === filter);
    if (foundSelectorFilter) {
      selectorFilterList = {
        ...selectorFilterList,
        [filter]: { ...filterState[filter] },
      };
    } else {
      generalFilterlList = {
        ...generalFilterlList,
        [filter]: { ...filterState[filter] },
      };
    }
  });
  if (Object.keys(generalFilterlList)?.length !== 0) {
    internalFilters = {
      ...internalFilters,
      ...generalFilter(generalFilterlList),
    };
  }
  if (Object.keys(selectorFilterList)?.length !== 0) {
    internalFilters = {
      ...internalFilters,
      ...selectorFilter(selectorFilterList),
    };
  }
  return internalFilters;
}

export function bookingFilterEngines(filterState) {
  let internalFilters;
  let generalFilterList = {};
  let selectorFilterList = {};

  const selectorObjList = Object.keys(SelectorFloatingFilterOptions);

  Object.keys(filterState).forEach(filter => {
    const foundSelectorFilter = selectorObjList.find(
      el => el === filter && filter !== 'flags',
    );
    if (foundSelectorFilter) {
      selectorFilterList = {
        ...selectorFilterList,
        [filter]: { ...filterState[filter] },
      };
    } else {
      generalFilterList = {
        ...generalFilterList,
        [filter]: { ...filterState[filter] },
      };
    }
  });

  if (Object.keys(generalFilterList)?.length !== 0) {
    internalFilters = {
      ...generalFilter(generalFilterList),
    };
  }

  if (Object.keys(selectorFilterList)?.length !== 0) {
    if (internalFilters?.and) {
      internalFilters = {
        and: [
          ...(internalFilters?.and ?? []),
          { ...selectorFilter(selectorFilterList) },
        ],
      };
    } else if (internalFilters) {
      internalFilters = {
        and: [
          { ...internalFilters },
          { ...selectorFilter(selectorFilterList) },
        ],
      };
    } else {
      internalFilters = {
        ...selectorFilter(selectorFilterList),
      };
    }
  }

  return internalFilters;
}
