import {
  CellValueChangedEvent,
  RowGroupOpenedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import Layout from 'components/Workspace/Layouts';
import { defaultBookingDateFilter } from 'components/config/date';
import Booking from 'containers/Dispatchs/Booking/DetailsScreen';
import useApp from 'context/app/hooks';
import { ModalMode } from 'context/app/types';
import useInvoicing from 'context/financials_invoicing/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import { invoicingScreenConfig } from './config';
import { invoicingGridOptions } from './gridOptions';

export default () => {
  //= ========================== init ===================//
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { openDialog } = useApp();

  const {
    invoiceWorkpaceData,
    selectedItems,
    bookingDetails,

    getBookingList,
    refreshWorkspaceData,
    getBookingById,
    resetItemDetails,

    flagRows,
    getBookingExpandDetails,
    modifyBooking,
    setSelectedItems,
  } = useInvoicing();

  const { currentAccount } = useAuthentication();

  //= ========================== handler ===================//

  const handleCellValueChanged = async (event: CellValueChangedEvent) => {
    const { data, column, value } = event;
    const colId = column.getId();
    const bookingId = data?.id;
    const schedulePriority =
      data?.schedulePriority === 0 ? 0 : Number(data?.schedulePriority) || null;
    const newColIds = [
      { old: 'bookingFlag', new: 'flags' },
      { old: 'memo', new: 'dispatchMemo' },
    ];
    const newColId = newColIds.find(({ old }) => old === colId)?.new || colId;

    const bookingData = {
      id: bookingId,
      currentVehicleAssetId: data?.currentVehicleAssetId,
      [newColId]: value,
      schedulePriority,
    };
    modifyBooking([bookingData]);
  };

  const handleRowGroupOpened = async (event: RowGroupOpenedEvent) => {
    const { data, api } = event;
    if (api.isToolPanelShowing()) {
      // if the toolpanel of sidebar on grid, close the tool panel
      api.closeToolPanel();
    }

    const bookingId = data?.id;
    getBookingExpandDetails(bookingId);
  };

  const _viewBookingRecord = (data: any) => {
    getBookingById(data?.id);
  };

  const gridOptions = {
    ...invoicingGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (selectedRows.find(row => row.id === rowNode.data.id))
          records.push(rowNode.data);
      });
      setSelectedItems([...records]);
    },

    onCellValueChanged: (event: CellValueChangedEvent) => {
      handleCellValueChanged(event);
    },
    onRowGroupOpened: event => {
      handleRowGroupOpened(event);
    },
  };

  //= ========================== useEffect ===================//

  useEffect(() => {
    const defaultDivisionIds = currentAccount?.userDivisionMappings?.map(
      d => d.divisionId,
    );

    dispatch(
      actions.setWorkspace({
        workspace: invoicingScreenConfig,
        query: {
          divisionIds: defaultDivisionIds?.length ? defaultDivisionIds : null,
          cursor: null,
          filters: {
            and: [
              {
                bookedDate: {
                  gte: defaultBookingDateFilter.from,
                },
              },
              {
                bookedDate: {
                  lte: defaultBookingDateFilter.to,
                },
              },
            ],
          },
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (bookingDetails) {
      openDialog({
        content: (
          <Booking
            bookingType={bookingDetails?.bookingType?.name}
            mode={ModalMode.MODIFY}
            status={bookingDetails?.bookingLegEventStatus?.code}
            record={bookingDetails}
          />
        ),
        dialogProps: {
          height: 90,
        },
      });
    }
    return () => {
      resetItemDetails();
    };
  }, [bookingDetails]);

  return (
    <Layout
      gridOptions={gridOptions}
      data={invoiceWorkpaceData}
      flagRows={flagRows}
      selectedItems={selectedItems}
      onViewRecord={_viewBookingRecord}
      onGetDataList={getBookingList}
      onRefreshDataList={refreshWorkspaceData}
    />
  );
};
