import type { MenuProps } from 'antd';
import { Card, Input, Menu, Tooltip } from 'antd';
import { getItemProps } from 'components/common/types';
import { RateMenuType } from 'components/Workspace/defaultConfigs/types/rates';
import React, { useEffect, useRef, useState } from 'react';

type Props = Readonly<{
  onChange?: any;
  customerList?: any;
  title?: string;
  height: string;
  currentAccount: any;
  selectedMenuItem?: any;
}>;

type MenuItem = Required<MenuProps>['items'][number];

function MenuList(props: Props) {
  // = ============================= init ===========================//
  const {
    onChange,
    customerList,
    height,
    title,
    currentAccount,
    selectedMenuItem,
  } = props;
  const typingTimeoutRef = useRef(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [customers, setCustomers] = useState(null);

  // = ============================= style ===========================//

  const menuItemStyle = {
    lineHeight: '20px',
    height: '25px',
    margin: 0,
    marginLeft: 0,
    fontSize: '13px',
  };

  // = ============================= hanlder ===========================//
  const performSearch = (value: string) => {
    if (value) {
      const result = customerList.filter(
        customer =>
          customer?.displayName?.toLowerCase()?.includes(value.toLowerCase()) ||
          customer?.childOrganization?.name
            ?.toLowerCase()
            ?.includes(value.toLowerCase()),
      );
      setCustomers([
        currentAccount?.organizationOrganizationMapping,
        ...result,
      ]);
    } else {
      setCustomers([
        currentAccount?.organizationOrganizationMapping,
        ...customerList,
      ]);
    }
  };

  const searchCustomer = (input: any) => {
    const value = input?.target?.value;
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      performSearch(value);
    }, 500);
  };

  const handleSetCurrentCustomer = item => {
    setCurrentCustomer(item);
    onChange({ ...item, type: RateMenuType.CUSTOMER });
  };

  const getItem = (item: getItemProps): MenuItem => {
    return item as MenuItem;
  };
  // = ============================= useEffect ===========================//

  useEffect(() => {
    setCustomers([
      currentAccount?.organizationOrganizationMapping,
      ...customerList,
    ]);
  }, [customerList]);

  // = ============================= render components ===========================//
  const customerItems: MenuProps['items'] = customers?.map(
    (customer, _index) => {
      const orgIdString = customer?.childOrganizationId?.toString();
      const customerName =
        customer?.displayName || customer?.childOrganization?.name;
      return getItem({
        label: (
          <Tooltip color="gray" title={`${customerName}(${orgIdString})`}>
            {_index === 0 ? `SCHEDULE RATES` : customerName}
          </Tooltip>
        ),
        key: orgIdString,
        style: {
          ...menuItemStyle,
          fontWeight: _index === 0 ? 'bold' : 'normal',
          color:
            currentCustomer?.key === orgIdString &&
            selectedMenuItem?.type === RateMenuType.CUSTOMER
              ? ''
              : 'black',
          backgroundColor:
            currentCustomer?.key === orgIdString &&
            selectedMenuItem?.type === RateMenuType.CUSTOMER
              ? ''
              : 'white',
        },
      });
    },
  );

  const renderCustomerMenu = () => {
    return (
      <Menu
        activeKey={currentCustomer?.key}
        style={{
          marginTop: '5px',
          overflowY: 'scroll',
          height,
        }}
        items={customerItems}
        onClick={handleSetCurrentCustomer}
      />
    );
  };

  return (
    <Card title={title}>
      <Input
        allowClear
        onChange={searchCustomer}
        placeholder="Search customer..."
      />
      {renderCustomerMenu()}
    </Card>
  );
}

export default MenuList;
