import { Tooltip } from 'antd';
import React from 'react';

const Field = ({
  tooltip,
  label,
  children,
  span = 6,
}: {
  tooltip?: string;
  label: string;
  children: React.ReactNode;
  span?: number;
}) => (
  <div
    className="flex flex-col"
    // tailwindcss can't interpolate dynamic style at runtime
    // so we have to use inline style.
    // See https://v2.tailwindcss.com/docs/just-in-time-mode
    style={{ gridColumn: `span ${span} / span ${span}` }}
  >
    <Tooltip placement="topLeft" title={tooltip} color="black">
      <span className="text-default text-[.8rem] truncate font-medium">
        {label}
      </span>
    </Tooltip>

    {children}
  </div>
);

export default Field;
