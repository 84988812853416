import { ICellRendererParams } from 'ag-grid-community';
import { Select } from 'antd';
import { ServiceProviderDefaultTypes } from 'components/Workspace/defaultConfigs/types/common';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

interface ISelectCellParams extends ICellRendererParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  const [value, setValue] = useState(props.value);

  const handleOnChange = value => {
    setValue(value);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      onChange={handleOnChange}
      bordered={false}
      options={ServiceProviderDefaultTypes}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
});
