// For file deletion
import { IconRef } from 'components/IconSheet';
import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';

export const userScreenConfig = {
  id: 'user-list',
  module: ModuleTypes.User,
  gridIcon: IconRef.menuUsers,
  initGridHeight: 225,
  toolbar: {
    actions: [
      {
        id: 'create',
        label: 'Add',
        tooltip: 'Add new user',
      },
      { id: 'delete', tooltip: 'Delete selected records' },
      {
        id: 'export',
        label: 'Export',
        tooltip: 'Export data in the grid to Excel or CSV file',
      },
    ],
  },

  screenViews: {
    label: 'Your Views',
  },
};
