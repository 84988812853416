import { ColDef, GridOptions } from 'ag-grid-community';
import { Layout } from 'antd';
import useBookings from 'context/bookings/hooks';
import useScreenViews from 'context/setting_screenViews/hooks';
import { generateId } from 'helpers/generator';
import React from 'react';
import { ModuleTypes } from '../../defaultConfigs/types/modules';
import ViewTabs from './ViewTabs';

const { Content } = Layout;

type Props = {
  module?: string;
  screenViews?: any;
  gridOptions?: GridOptions;
};

function ScreenView(props: React.PropsWithChildren<Props>) {
  const { screenViews, gridOptions, module, children } = props;
  const { bookingAttributeTemplate } = useBookings();

  const {
    modifyScreenViewRegistry,
    screenViewSettings,
    currentView,
    updatingTab,
  } = useScreenViews();

  const handleAddTab = tabName => {
    const newTab = handleDefaultTabGenerator(tabName);
    const newTabs = [newTab, ...(currentView?.tabs ?? [])];
    const newScreenViews = screenViewSettings?.screenViews?.map(item =>
      item.viewId === currentView?.viewId
        ? {
            ...currentView,
            tabs: newTabs,
            activeTab: newTab.tabId,
          }
        : item,
    );

    modifyScreenViewRegistry(
      {
        ...screenViewSettings,
        screenViews: newScreenViews,
        activeTab: newTab.tabId,
      },
      updatingTab,
    );
  };

  const handleDefaultTabGenerator = tabName => {
    const tabId = generateId(false, 6);
    let columnDefs: ColDef[] = gridOptions.columnDefs.map(col => {
      return {
        ...col,
        hide: false,
        colId: col[`colId`] || col[`field`],
        rowGroup: false,
        sort: null,
      };
    });

    if (module === ModuleTypes.Booking.value) {
      const attributeCols = bookingAttributeTemplate?.bookingAttributes?.map(
        att => {
          return {
            headerName: att?.displayName,
            colId: att?.name,
            field: att?.name,
            width: 100,
            rowGroup: false,
            sort: null,
          };
        },
      );
      columnDefs = [...columnDefs, ...attributeCols];
    }

    return {
      tabId,
      tabName,
      columnDefs,
      filterModel: {},
      columnGroupState: [],
      sortModel: [],
    };
  };

  const content = (
    <Layout>
      <Content
        style={{
          minHeight: 280,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Content>
      <ViewTabs onAddTab={handleAddTab} hasView={currentView} />
    </Layout>
  );

  if (screenViews) {
    return <Layout className="site-layout">{content}</Layout>;
  }
  return <>{children}</>;
}

export default ScreenView;
