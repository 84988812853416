import { ColDef } from 'ag-grid-community';
import { BookingAttributeModel } from 'api/graphql/generated/serviceTypesAndHooks';
import { getDateColorRules } from 'components/ReactGrid/renderers/styleFormatter';
import { CustomAttributeName } from 'components/Workspace/defaultConfigs/types/customAttributes';
import { dateCalculateRule } from 'helpers/date';
import { dateFilterParams } from 'helpers/filterParamsClientSideFilter';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const refineAttributeColumn = (att, colDef, valueName) => {
  if (att?.attributeControlTypeId === 6) {
    refineDualCheckedColumn(colDef, valueName);
  }

  if (att?.attributeControlTypeId === 20) {
    refineDatePickerColumn(att, colDef);
  }

  return colDef;
};

const refineDualCheckedColumn = (colDef, valueName) => {
  colDef.valueGetter = params => {
    if (params?.data[valueName]) {
      const value = params?.data[valueName];
      if (value?.includes('|')) {
        const dualValues = value?.split('|');
        return {
          webChecked: dualValues[0] === 'true',
          mobileChecked: dualValues[1] === 'true',
        };
      }
      return {
        webChecked: value === 'true',
      };
    }
    return null;
  };
  colDef.cellRenderer = 'DualCheckedCell';
};

const refineDatePickerColumn = (att, colDef) => {
  colDef.width = 180;
  colDef.filter = 'agDateColumnFilter';
  colDef.filterParams = dateFilterParams;

  if (att?.name === 'ContainerDetentionDate') {
    colDef.valueGetter = params => {
      if (params?.data?.containerDetentionDate) {
        return dateCalculateRule(params?.data?.containerDetentionDate);
      }
      return null;
    };
    colDef.cellClassRules = getDateColorRules(5, 3);
  } else if (att?.name === 'ContainerDemurrageDate') {
    colDef.valueGetter = params => {
      if (params?.data?.containerLastFreeTime) {
        return dateCalculateRule(params?.data?.containerLastFreeTime);
      }
      return null;
    };
    colDef.cellClassRules = getDateColorRules(4, 2);
  } else {
    colDef.cellRendererParams = {
      type: 'datetime',
    };
  }
};

export const generateCustomAttributeColumn = bookingAttributeTemplate => {
  return bookingAttributeTemplate?.bookingAttributes?.map(att => {
    const valueName = CustomAttributeName.find(
      el => el?.label?.toLocaleLowerCase() === att?.name?.toLocaleLowerCase(),
    )?.value;

    const colDef: ColDef = {
      headerName: att?.displayName,
      colId: att?.name,
      field: valueName,
      width: 140,
    };
    return refineAttributeColumn(att, colDef, valueName);
  });
};

export const convertedCustomeAttributeKeyValuePair = (
  customAttributes: [BookingAttributeModel],
  dataToConvert,
  oldBookingAttributes?,
) => {
  return customAttributes.flatMap(att => {
    if (
      dataToConvert[att?.name] === undefined ||
      dataToConvert[att?.name] === null
    ) {
      return [];
    }
    let value = `${dataToConvert[att?.name]}`;
    if (att?.attributeControlTypeId === 20) {
      value = dayjs.utc(value).toISOString() || '';
    }
    if (att?.attributeControlTypeId === 6) {
      if (!value?.includes('|')) {
        // what is false|false ?
        if (value === 'false') value = 'false|false';
      } else if (value === 'true') {
        const oldValue = oldBookingAttributes?.find(
          item => item?.id === att?.id,
        )?.defaultValue;
        if (oldValue?.includes('|')) {
          const dualValues = oldValue?.split('|');
          dualValues[0] = value;
          value = `${dualValues[0]}|${dualValues[1]}`;
        }
      }
    }

    return { key: `booking_attribute_${att?.id}`, value };
  });
};
