import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { jobTypes } from '../../components/ContainerInfo/types';

// https://github.com/jquense/yup
const schema = yup
  .object()
  .shape({
    ContainerJobType: yup.number(),
    organizationFreightPayerId: yup
      .number()
      .required({ message: 'Select Freight Payer' }),
    divisionId: yup.number().required({ message: 'Division required' }),
    pickup: yup.object().shape({
      organizationLocationId: yup
        .number()
        .required({ message: 'Pickup Site Location is required' }),
    }),
    // conditional validation
    activity: yup.object().when('ContainerJobType', {
      is: val => val !== jobTypes.MOV,
      then: schema =>
        schema.shape({
          organizationLocationId: yup
            .number()
            .required({ message: 'Activity Site location is required' }),
        }),
    }),
    delivery: yup.object().shape({
      organizationLocationId: yup
        .number()
        .required({ message: 'Delivery Site Location is required' }),
    }),
  })
  .required();
export { schema };

const duplicateSchema = yup.object().shape({
  quantity: yup
    .number()
    .positive({ message: 'Number of copies must be greater than 0' })
    .required({ message: 'Number of copies is required' }),
});

export { duplicateSchema };
export const duplicateResolver = yupResolver(duplicateSchema);
export default yupResolver(schema);
