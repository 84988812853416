import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CellValueChangedEvent, GridApi } from 'ag-grid-community';
import AGGrid from 'components/ReactGrid';
import { PrimaryAction } from 'components/Workspace/ToolbarActions/ActionButtons';
import { TContact } from 'containers/Locations/Places/DetailsScreen/store';
import { useFormHooks } from 'context/FormHooksContext';
import gridApi from 'helpers/gridAPI';
import React, { useEffect } from 'react';
import { gridOptions } from './gridOptions';

const gridApiRef = 'place-contact-form-grid';
export const contactsRegistedFieldname = 'contactInputs';

export default function ContactForm() {
  const [rowData, setRowData] = React.useState([]);
  const { setValue, getValues } = useFormHooks();
  // restore contact grid data from form-hooks
  useEffect(() => {
    const value = getValues(contactsRegistedFieldname) || [];
    setRowData(() => [...value]);
  }, [getValues]);

  const handleDeleteRecord = param => {
    const { api, data } = param;

    gridApi.remove({ data, gridApiRef: api });
    const { items, rowData: updatedRowData } = getGridData(api, param);
    // set default to the first item if the current default contact is deleted
    const defaultItem = updatedRowData.find(x => x.isDefaultContact);
    if (!defaultItem && updatedRowData.length > 0) {
      updatedRowData[0].isDefaultContact = true;
    }
    setValue(contactsRegistedFieldname, items);
    setRowData([...updatedRowData]);
  };

  function _onAddContact() {
    const apiRef = window[`${gridApiRef}`];
    const data = { isDefaultContact: rowData.length === 0 } as TContact;

    gridApi.add({ data, gridApiRef: apiRef });
    const { items } = getGridData(window[gridApiRef]);

    setValue(contactsRegistedFieldname, items);
    setRowData([...rowData, data]);

    // TODO: set focus on new cell row
  }

  function _onCellValueChanged(e: CellValueChangedEvent) {
    const { items, rowData } = getGridData(window[gridApiRef], e);
    setValue(contactsRegistedFieldname, items);
    setRowData(rowData);
  }

  return (
    <div className="p-2">
      <div className="flex justify-start mb-1">
        <PrimaryAction icon={faPlusCircle} onClick={_onAddContact} />
      </div>
      <div id="dg-grid" className="h-[300px] mt-1">
        <AGGrid
          {...gridOptions}
          apiRef={gridApiRef}
          context={{ handleDeleteRecord }}
          onCellValueChanged={_onCellValueChanged}
          rowData={rowData}
          hideOverlay
        />
      </div>
    </div>
  );

  function getGridData(api: GridApi, e?: CellValueChangedEvent) {
    const items = [];
    const rowData = [];

    api.forEachNode(node => {
      const item = {
        id: node.data.id,
        firstName: node.data.firstName,
        lastName: node.data.lastName,
        email: node.data.email,
        phone: node.data.phone,
        mobile: node.data.mobile,
        isDefaultContact: node.data.isDefaultContact,
        isDeleted: node.data.isDeleted,
      };

      delete node.data['__typename'];
      items.push(item);
      rowData.push(node.data);
    });

    const updatedItems = items.map((item, index) => {
      if (e?.data.isDefaultContact) {
        item.isDefaultContact = e?.rowIndex === index;
      }
      return item;
    });

    const updatedRowData = rowData.map((row, index) => {
      if (e?.data.isDefaultContact) {
        row.isDefaultContact = e?.rowIndex === index;
      }
      return row;
    });

    return {
      items: updatedItems,
      rowData: updatedRowData,
    };
  }
}
