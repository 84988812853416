import dayjs from 'dayjs';

export const selectorFilterParams = {
  debounceMs: 1000,
  newRowsAction: 'keep',
  // refreshValuesOnOpen: true,
  suppressAndOrCondition: true,
  filterOptions: [
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: ([_filterValue], _cellValue) => {
        return _filterValue
          .toLowerCase()
          .split(',')
          .includes(_cellValue.toLowerCase());
      },
    },
    {
      displayKey: 'notEqual',
      displayName: 'not Equal',
      predicate: ([_filterValue], _cellValue) => {
        return !_filterValue
          .toLowerCase()
          .split(',')
          .includes(_cellValue.toLowerCase());
      },
    },
  ],
};

export const flagsFilterParams = {
  debounceMs: 1000,
  newRowsAction: 'keep',
  // refreshValuesOnOpen: true,
  suppressAndOrCondition: true,
  filterOptions: [
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: ([_filterValue], _cellValue) => {
        return _filterValue
          ?.toLowerCase()
          ?.split(',')
          .some(value => _cellValue.includes(value));
      },
    },
    {
      displayKey: 'notEqual',
      displayName: 'not Equal',
      predicate: ([_filterValue], _cellValue) => {
        return !_filterValue
          ?.toLowerCase()
          ?.split(',')
          .some(value => _cellValue.includes(value));
      },
    },
  ],
};

export const booleanSelectorFilterParams = {
  debounceMs: 1000,
  newRowsAction: 'keep',
  // refreshValuesOnOpen: true,
  suppressAndOrCondition: true,
  filterOptions: [
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: ([_filterValue], _cellValue) => {
        return 1; // always true
      },
    },
    {
      displayKey: 'notEqual',
      displayName: 'not Equal',
      predicate: ([_filterValue], _cellValue) => {
        return 1; // always true
      },
    },
  ],
};

export const dateFilterParams = {
  minValidYear: 2022,
  filterOptions: [
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'inRange',
  ],
  // use inRangeInclusive: true for the range filter to include the selected
  // from and to dates. Setting it false would fetch only the inbetween dates
  inRangeInclusive: true,
  comparator: (_filterLocalDateAtMidnight, _cellValue) => {
    return 1; // return always true.
  },
};

export const nextMileStoneDateFilterParams = {
  minValidYear: 2022,
  // filterPlaceholder: 'dd/mm/yyyy', not workng..
  // dateFormat: 'dd/mm/yyyy', not workng..
  // inRangeFloatingFilterDateFormat: 'dd/mm/yyyy', not workng..
  filterOptions: [
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'inRange',
  ],
  // use inRangeInclusive: true for the range filter to include the selected
  // from and to dates. Setting it false would fetch only the inbetween dates
  inRangeInclusive: true,
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateData = {
      localDateAsString: dayjs(filterLocalDateAtMidnight).format('DD/MM/YYYY'),
      cellValue: cellValue?.split(' ')[0],
    };

    const formattedDateArray = Object.keys(dateData).map(el => {
      const dateParts = dateData[el].split('/');
      return new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
    });

    if (formattedDateArray[0].getTime() === formattedDateArray[1].getTime()) {
      return 0;
    }

    if (formattedDateArray[1] < formattedDateArray[0]) {
      return -1;
    }

    if (formattedDateArray[1] > formattedDateArray[0]) {
      return 1;
    }
    return null;
  },
};
