import {
  Division,
  OrganizationLocation,
  RateCommodity,
  useGetDivisionsLazyQuery,
  useGetImpedimentsLazyQuery,
  useGetRateCommoditiesLazyQuery,
  useGetRecentPlacesLazyQuery,
  useGetSealsLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectDivisions,
  selectImpediments,
  selectRateCommodities,
  selectRecentPlaces,
  selectSeals,
  sliceKey,
} from './slice';

export default function usePredefinedData() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const impediments = useSelector(selectImpediments);
  const seals = useSelector(selectSeals);
  const rateCommodities: RateCommodity = useSelector(selectRateCommodities);
  const recentPlaces: {
    PICKUP: { nodes: OrganizationLocation[] };
    ACTIVITY: { nodes: OrganizationLocation[] };
    DELIVERY: { nodes: OrganizationLocation[] };
    EXTRA: { nodes: OrganizationLocation[] };
  } = useSelector(selectRecentPlaces);
  const divisions: Division[] = useSelector(selectDivisions);

  const [getRecentPlacesQuery] = useGetRecentPlacesLazyQuery({
    onCompleted: response => {
      dispatch(
        actions.setRecentPlaces({
          recentPlaces: response,
        }),
      );
    },
  });

  const getRecentPlaces = (dateTime, first) => {
    getRecentPlacesQuery({
      variables: {
        dateTime,
        first,
      },
    });
  };

  const [
    getImpedimentQuery,
    { loading: pendingImpediments },
  ] = useGetImpedimentsLazyQuery({
    onCompleted: response => {
      if (response?.impediments?.nodes) {
        const nodes = response.impediments.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });
        dispatch(
          actions.setImpedimentList({
            impediments: nodes,
          }),
        );
      }
    },
  });

  const getImpediments = () => {
    getImpedimentQuery({
      variables: { filters: { isDeleted: { neq: true } } },
    });
  };

  const [getSealQuery, { loading: pendingSeals }] = useGetSealsLazyQuery({
    onCompleted: response => {
      if (response?.seals?.nodes) {
        const nodes = response.seals.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });
        dispatch(
          actions.setSealList({
            seals: nodes,
          }),
        );
      }
    },
  });

  const getSeals = () => {
    getSealQuery({
      variables: { filters: { isDeleted: { neq: true } } },
    });
  };

  const [
    getRateCommodityQuery,
    { loading: pendingRateCommodities },
  ] = useGetRateCommoditiesLazyQuery({
    onCompleted: response => {
      if (response?.rateCommodities?.nodes) {
        const nodes = response.rateCommodities.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });
        dispatch(
          actions.setRateCommodities({
            rateCommodities: nodes,
          }),
        );
      }
    },
  });

  const getRateCommodities = variables => {
    getRateCommodityQuery({
      variables,
    });
  };

  const [
    getDivisionQuery,
    { loading: pendingDivisions },
  ] = useGetDivisionsLazyQuery({
    onCompleted: response => {
      if (response?.allDivisions?.nodes) {
        const nodes = response.allDivisions.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });
        dispatch(
          actions.setDivisions({
            divisions: nodes,
          }),
        );
      }
    },
  });

  const getDivisions = () => {
    getDivisionQuery({
      variables: { filters: { isDeleted: { neq: true } } },
    });
  };

  return {
    getImpediments,
    getSeals,
    getRateCommodities,
    getRecentPlaces,
    getDivisions,
    recentPlaces,
    rateCommodities,
    impediments,
    seals,
    divisions,
    pendingImpediments,
    pendingRateCommodities,
    pendingSeals,
    pendingDivisions,
  };
}
