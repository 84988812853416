import { message } from 'antd';
import { TGridColumnType } from 'components/ReactGrid/types';
import { WorkspaceQuery } from 'components/Workspace/types';
import { defaultBookingDateFilter } from 'components/config/date';
import useAuthentication from 'context/security_authentication/hook';
import { selectQuery, selectWorkspaces } from 'context/workspace/selectors';
import { generalFilterEngines } from 'helpers/filterEngines';
import { sortEngines } from 'helpers/sortEngines';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModuleTypes } from '../defaultConfigs/types/modules';
import GridPanel from './GridPanel';
import { StyledForm } from './styles';

type Props = Readonly<{
  gridOptions?: any;
  data?: any;
  flagRows?: any[];
  selectedItems?: any[];
  onGetDataList?: (query: any) => void;
  onRefreshDataList?: () => void;
  onCreateNew?: () => void;
  onDuplicate?: () => void;
  onViewRecord?: (record: any) => void;
  onDelete?: (ids: number[]) => Promise<{ data: any; message: string }>;
}>;

export default function Layout(props: Props) {
  const workspace = useSelector(selectWorkspaces);
  const [loading, setLoading] = useState(false);
  const { currentAccount } = useAuthentication();
  const workspaceQuery: WorkspaceQuery = useSelector(selectQuery);
  const [mainQuery, setMainQuery] = useState(null);
  const [aggridFilters, setAggridFilters] = useState(null);
  const [dateFilters, setDateFilters] = useState({
    and: [
      {
        bookedDate: {
          gte: defaultBookingDateFilter.from,
        },
      },
      {
        bookedDate: {
          lte: defaultBookingDateFilter.to,
        },
      },
    ],
  });
  const [divisionIds, setDivisionIds] = useState(
    currentAccount?.userDivisionMappings?.map(d => d.divisionId),
  );

  // =================== Handlers ===========================//

  const handleApplyFilter = query => {
    if (workspace?.dateQueryFormatter) {
      setLoading(true);
      let externalDateFilter;
      let filterAnd = [];

      const { divisionIds, ...dateQuery } = query;

      const formattedQuery = workspace?.dateQueryFormatter(dateQuery);
      if (workspace?.filterEngines) {
        externalDateFilter = workspace.filterEngines(formattedQuery);
      }

      if (aggridFilters?.and) {
        filterAnd = [
          ...(aggridFilters.and ?? []),
          ...(externalDateFilter?.and ?? []),
        ];
      } else {
        filterAnd = [...(externalDateFilter?.and ?? [])];
      }

      const newDivisionIds = divisionIds.length > 0 ? divisionIds : null;
      setDivisionIds(newDivisionIds);
      setDateFilters(externalDateFilter);
      setMainQuery({
        ...mainQuery,
        divisionIds: newDivisionIds,
        filters: returnNullIfEmpty({
          ...aggridFilters,
          ...(filterAnd.length > 0 && {
            and: filterAnd,
          }),
        }),
      });
    }
  };

  const handleClearFilter = () => {
    setLoading(true);
    if (workspace?.dateQueryFormatter) {
      const dateFilters = handleSetDefaultDateRange();
      const defaultDivisionIds = currentAccount?.userDivisionMappings?.map(
        d => d.divisionId,
      );
      setDivisionIds(defaultDivisionIds);
      setAggridFilters(null);
      setMainQuery({
        divisionIds: defaultDivisionIds?.length ? defaultDivisionIds : null,
        filters: returnNullIfEmpty({
          ...dateFilters,
        }),
      });
    } else {
      getInitialData();
    }
  };

  const handleSetDefaultDateRange = () => {
    const dateTypeSelector = workspace?.toolbar?.filters?.find(
      el => el.field === 'dateTypeSelector',
    );
    const dateRangePicker = workspace?.toolbar?.filters?.find(
      el => el.field === 'dateRangePicker',
    );
    const query = {
      [dateTypeSelector.field]: dateTypeSelector.defaultValue,
      [dateRangePicker.field]: dateRangePicker.defaultValue,
    };
    const formattedQuery = workspace?.dateQueryFormatter(query);
    const internalFilters = workspace.filterEngines(formattedQuery);
    const dateFilters = {
      ...internalFilters,
      ...workspaceQuery?.filters,
    };

    setDateFilters(dateFilters);
    return dateFilters;
  };

  const handleInternalFilterChanged = (filterState, sortState) => {
    setLoading(true);
    let externalDateFilter;
    let filterAnd = [];
    const refinedSort = sortEngines(sortState, workspace?.module?.value);
    if (workspace?.filterEngines) {
      externalDateFilter = workspace.filterEngines(filterState);
      if (externalDateFilter?.and) {
        const dateFilter = dateFilters?.and;
        filterAnd = [...(externalDateFilter.and ?? []), ...(dateFilter ?? [])];
      } else {
        filterAnd = dateFilters?.and;
      }
    } else {
      externalDateFilter = generalFilterEngines(filterState);
    }

    setAggridFilters(externalDateFilter);
    const newQuery = {
      ...mainQuery,
      ...(workspace?.module?.value === ModuleTypes.Booking.value &&
        workspace?.module?.value === ModuleTypes.Invoice.value && {
          divisionIds: divisionIds?.length ? divisionIds : null,
        }),
      filters: returnNullIfEmpty({
        ...externalDateFilter,
        ...(filterAnd?.length && {
          and: filterAnd,
        }),
      }),
      ...(refinedSort?.length && {
        order: refinedSort,
      }),
    };
    setMainQuery(newQuery);
  };

  const handleInternalSortChanged = (
    sortState: [{ colId: string; sort: string; sortIndex: number }],
  ) => {
    const refinedSort = sortEngines(sortState, workspace?.module?.value);
    if (refinedSort?.length) {
      setMainQuery(prevState => ({
        ...prevState,
        order: refinedSort,
      }));
    }
  };

  const handleGridPagination = () => {
    try {
      if (
        props.data?.pageInfo?.hasNextPage &&
        props.data?.pageInfo?.endCursor &&
        !loading
      ) {
        setLoading(true);
        const cursor = props.data.pageInfo.endCursor;
        props.onGetDataList({ ...mainQuery, cursor });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowDoubleClick = event => {
    const record = event.data;
    const colType = event.column.colDef.type;
    const suppressRowDoubleClick =
      colType === TGridColumnType.ClickToChangeColumn ||
      colType === TGridColumnType.EditableColumn;
    if (!suppressRowDoubleClick) {
      props.onViewRecord?.(record);
    }
  };

  const handleDeleteRecord = async (ids: number[]) => {
    const result = await props?.onDelete(ids);
    if (result?.data) {
      message.success(result.message);
      props?.onGetDataList(mainQuery || workspaceQuery);
    } else {
      message.error(result.message);
    }
  };

  const getInitialData = () => {
    const newInitWorkspaceQuery = {
      ...workspaceQuery,
      filters: workspaceQuery?.filters ? workspaceQuery.filters : null,
    };
    const initQuery = newInitWorkspaceQuery || {
      filters: null,
      cursor: null,
      order: null,
    };
    setMainQuery(initQuery);
  };

  const returnNullIfEmpty = newFilter => {
    return Object.keys(newFilter).length === 0 ? null : newFilter;
  };

  // =================== UseEffects =========================== //

  useEffect(() => {
    if (mainQuery) {
      setLoading(true);
      props?.onGetDataList(mainQuery);
    }
    return () => {
      if (props?.onRefreshDataList) {
        props?.onRefreshDataList();
      }
    };
  }, [mainQuery]);

  useEffect(() => {
    setLoading(props.data?.loading);
  }, [props.data]);

  // =================== Render components =========================== //

  const renderDataPanel = () => {
    return (
      <GridPanel
        gridOptions={props?.gridOptions}
        module={workspace?.module?.value}
        loading={loading}
        data={props.data}
        flagRows={props.flagRows}
        selectedItems={props.selectedItems}
        gridHeight={205}
        toolbar={workspace?.toolbar}
        screenViews={workspace?.screenViews}
        onApplyFilter={handleApplyFilter}
        onClearFilter={handleClearFilter}
        onGridPagination={handleGridPagination}
        onGridRowDoubleClick={handleRowDoubleClick}
        onViewRecord={props.onViewRecord}
        onInternalFilterChanged={handleInternalFilterChanged}
        onInternalSortChanged={handleInternalSortChanged}
        onCreateNew={props.onCreateNew}
        onDuplicate={props.onDuplicate}
        onDelete={handleDeleteRecord}
        onGetInitialData={getInitialData}
      />
    );
  };

  const renderContent = () => {
    if (workspace) {
      return <div className="h-full">{renderDataPanel()}</div>;
    }
    return null;
  };

  return <StyledForm>{renderContent()}</StyledForm>;
}
