import {
  faEdit,
  faPlusCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MenuProps } from 'antd';
import { Card, Input, Menu, Popconfirm, Tooltip, message } from 'antd';
import { RateMenuType } from 'components/Workspace/defaultConfigs/types/rates';
import { getItemProps } from 'components/common/types';
import useApp from 'context/app/hooks';
import useBookingRates from 'context/financials_rate/hooks';
import { actions } from 'context/financials_rate/slice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import FafGroupForm from '../../DetailsScreen/FafGroupForm';

type Props = Readonly<{
  onChange?: any;
  rateGroupList?: any;
  title?: string;
  onHandleFafGroupSetting?: any;
  height: string;
}>;

type MenuItem = Required<MenuProps>['items'][number];

function MenuList(props: Props) {
  //= ============================= init ===========================//
  const {
    onChange,
    rateGroupList,
    onHandleFafGroupSetting,
    height,
    title,
  } = props;
  const typingTimeoutRef = useRef(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [rateGroups, setRateGroups] = useState(null);
  const { deleteFafGroupNew } = useBookingRates();
  const { openDialog, closeDialog } = useApp();
  const dispatch = useDispatch();

  //= ============================= style ===========================//

  const menuItemStyle = {
    margin: 0,
    marginLeft: 0,
    padding: 0,
  };

  //= ============================= hanlder ===========================//

  const searchGroupInput = input => {
    const value = input?.target?.value;
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) {
        const result = rateGroupList.filter(group =>
          group?.name?.toLowerCase()?.includes(value.toLowerCase()),
        );
        setRateGroups(result);
      } else {
        setRateGroups(rateGroupList);
      }
    }, 500);
  };

  const handleSetCurrentGroup = item => {
    setCurrentGroup(item);
    dispatch(
      actions.setSelectedFafGroupNew({
        selectedFafGroupNew: item,
      }),
    );
    onChange({ ...item, type: RateMenuType.RATEGROUP });
  };

  const getItem = (item: getItemProps): MenuItem => {
    return item as MenuItem;
  };
  //= ============================= useEffect ===========================/

  useEffect(() => {
    setRateGroups(rateGroupList);
  }, [rateGroupList]);

  const handleConfirm = async item => {
    const result = await deleteFafGroupNew(item.id);
    if (result?.data) {
      message.success('Delete FafGroup Successfully');
      dispatch(
        actions.setSelectedFafGroupNew({
          selectedFafGroupNew: null,
        }),
      );
    } else {
      message.error('Cannot Delete FafGroup');
    }
  };

  const onRateUpdate = () => {
    closeDialog();
  };
  const handleShowModal = item => {
    openDialog({
      content: <FafGroupForm afterValidation={onRateUpdate} fafGroup={item} />,
      dialogProps: {
        closable: false,
        height: '300px',
        width: '500px',
      },
    });
  };

  //= ============================= render components ===========================//
  const rateGroupItems: MenuProps['items'] = rateGroups?.map(item => {
    const rateGroupName = item?.name || item?.displayName;
    return getItem({
      label: (
        <Tooltip color="gray" title={`${rateGroupName}`}>
          {rateGroupName}
        </Tooltip>
      ),
      key: item?.id?.toString(),
      icon: (
        <div
          style={{
            position: 'absolute',
            right: '5px',
          }}
        >
          <FontAwesomeIcon
            icon={faEdit}
            style={{
              textAlign: 'center',
              marginTop: '12px',
            }}
            onClick={() => handleShowModal(item)}
          />
          <Popconfirm
            placement="top"
            title="Are you sure to delete this group?"
            onConfirm={() => handleConfirm(item)}
            okText="Yes"
            cancelText="No"
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{
                textAlign: 'center',
                marginTop: '12px',
                marginLeft: '12px',
                color: 'red',
              }}
            />
          </Popconfirm>
        </div>
      ),
      style: {
        ...menuItemStyle,
      },
    });
  });

  const renderGroupMenu = () => {
    return (
      <Menu
        activeKey={currentGroup?.key}
        style={{
          marginTop: '5px',
          overflowY: 'scroll',
          height,
        }}
        items={rateGroupItems}
        onClick={handleSetCurrentGroup}
      />
    );
  };

  return (
    <Card
      title={title}
      extra={
        <Tooltip placement="top" title={`Settings`} color="gray">
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faPlusCircle}
            onClick={onHandleFafGroupSetting}
          />
        </Tooltip>
      }
    >
      <Input
        allowClear
        onChange={searchGroupInput}
        placeholder="Search Group..."
      />
      {renderGroupMenu()}
    </Card>
  );
}

export default MenuList;
