import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';

export const sortEngines = (
  sortState: [{ colId: string; sort: string; sortIndex: number }],
  module: string,
) => {
  const dispatchScreens = [
    ModuleTypes.Booking.value,
    ModuleTypes.Invoice.value,
  ];
  const sortedSortState = sortState.sort((a, b) => {
    return a.sortIndex - b.sortIndex;
  });
  const generalSort = sortedSortState?.map(el => {
    const newKeys = el.colId.split('.');
    return generalSortGenerator(newKeys, el.sort.toUpperCase());
  });
  if (
    !generalSort.find(el => el.colId === 'bookedDate') &&
    dispatchScreens.includes(module)
  ) {
    // This is a base sort. Bring up Lasted bookings.
    generalSort.push({ bookedDate: 'DESC' });
  }
  return generalSort;
};

function generalSortGenerator(newKeys, direction) {
  let result;

  function recursionGeneral(key, count, direction) {
    if (count === 1) {
      return { [key[key.length - count]]: direction };
    }
    return {
      [key[key.length - count]]: recursionGeneral(key, count - 1, direction),
    };
  }

  result = recursionGeneral(newKeys, newKeys.length, direction);
  return result;
}
