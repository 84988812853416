import {
  faEdit,
  faPlusCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  Empty,
  Input,
  Menu,
  MenuProps,
  Popconfirm,
  Row,
  Tooltip,
  Transfer,
  message,
} from 'antd';
import { useGetRateGroupOrgMappingsLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import { getItemProps } from 'components/common/types';
import useBookingRates from 'context/financials_rate/hooks';
import {
  selectCustomerList,
  selectRateGroups,
} from 'context/financials_rate/slice';
import useAuthentication from 'context/security_authentication/hook';
import { isAllow } from 'helpers/checkPermission';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'twin.macro';
import { ModuleTypes } from '../../../../../components/Workspace/defaultConfigs/types/modules';

type MenuItem = Required<MenuProps>['items'][number];

const StyledMenu = styled(Menu)`
  .ant-menu-item {
    padding-right: 50px;
  }
  .ant-menu-title-content {
    margin-inline-start: 3px !important;
  }
`;

function RateGroup() {
  // = =========initial===========//
  const [customerList, setCustomerList] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]); // assigned customer list
  const [rateGroupName, setRateGroupName] = useState(null);
  const [editingRateGroup, setEditingRateGroup] = useState(null);
  const [currentRateGroup, setCurrentRateGroup] = useState(null);

  const rateGroupList = useSelector(selectRateGroups);
  const organizationList = useSelector(selectCustomerList);

  const {
    createRateGroup,
    modifyRateGroup,
    createRateGroupOrgMapping,
    deleteRateGroupOrgMapping,
    deleteRateGroup,
  } = useBookingRates();
  const [assignedGroups, setAssignedGroups] = useState([]);
  const { userPermissions, currentAccount } = useAuthentication();

  // = ========= data processing =============//

  const [getCustomerListQuery] = useGetRateGroupOrgMappingsLazyQuery({
    onCompleted: response => {
      const ordIDs =
        response?.rateGroupOrganizationMappings?.nodes?.map(item => {
          return item?.organizationId;
        }) || [];
      let customerListData = [];
      const disableCustomerlist = [];
      if (organizationList) {
        rateGroupList?.forEach(el => {
          el?.rateGroupOrganizationMappings?.forEach(item => {
            if (!ordIDs?.find(ordID => ordID === item?.organizationId)) {
              disableCustomerlist.push(item?.organization);
            }
          });
        });
        customerListData = organizationList?.map(item => {
          if (
            disableCustomerlist?.find(
              customer => customer?.id === item?.childOrganizationId,
            )
          ) {
            return {
              key: item?.childOrganizationId,
              title: item?.name || item?.displayName,
              disabled: true,
            };
          }
          return {
            key: item?.childOrganizationId,
            title: item?.name || item?.displayName,
          };
        });
      }
      setCustomerList(customerListData);
      setAssignedGroups(response?.rateGroupOrganizationMappings?.nodes);
      setTargetKeys(ordIDs);
      return undefined;
    },
  });

  // = ======== event handlers=============//

  const handleAddRateGroup = () => {
    const newGroup = {
      name: 'New Group',
      subscriberOrganizationId:
        currentAccount?.organizationOrganizationMapping?.childOrganizationId,
    };
    createRateGroup(newGroup);
  };

  const handleRateGroupNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    setRateGroupName(value);
  };

  const handleEditRateGroup = item => {
    setEditingRateGroup(item);
    setRateGroupName(item?.name);
  };

  const handleSaveRateGroup = () => {
    if (rateGroupName?.length > 0) {
      const input = {
        id: currentRateGroup?.id,
        name: rateGroupName,
      };
      modifyRateGroup(input);
      setEditingRateGroup(null);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent): any => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleSaveRateGroup();
    }
  };

  const handleConfirm = async item => {
    const result = await deleteRateGroup(item.id);
    if (result?.data) {
      message.success('Delete RateGroup Successfully');
    } else {
      message.error('Cannot Delete RateGroup');
    }
  };

  const handleSetCurrentRateGroup = rateGroup => {
    setCurrentRateGroup(rateGroup);
  };

  const getItem = (item: getItemProps): MenuItem => {
    return item as MenuItem;
  };

  // = =========== useEffects ============//

  useEffect(() => {
    let data = [];
    const disableCustomerlist = [];
    if (organizationList) {
      rateGroupList?.forEach(el => {
        el?.rateGroupOrganizationMappings?.forEach(item =>
          disableCustomerlist.push(item?.organization),
        );
      });
      data = organizationList?.map(item => {
        return {
          key: item?.childOrganizationId,
          title: item?.displayName || item?.childOrganization?.name,
        };
      });
    }
    setCustomerList(data);
  }, [organizationList]);

  useEffect(() => {
    if (currentRateGroup) {
      getCustomerListQuery({
        variables: {
          filters: {
            rateGroupId: { eq: currentRateGroup?.id },
            isDeleted: { neq: true },
          },
          cursor: null,
          order: null,
        },
      });
    }
  }, [currentRateGroup]);

  const handleChange = (newTargetKeys, direction, moveKeys) => {
    if (currentRateGroup) {
      if (direction === 'right') {
        createRateGroupOrgMapping({
          organizationIds: moveKeys,
          rateGroupId: currentRateGroup?.id,
        }).then(res => {
          if (
            res.data?.addRateGroupOrganizationMapping
              ?.rateGroupOrganizationMapping
          ) {
            setAssignedGroups([
              ...assignedGroups,
              ...(res.data?.addRateGroupOrganizationMapping
                ?.rateGroupOrganizationMapping || []),
            ]);
            setTargetKeys(newTargetKeys);
            message.success('Assign user(s) to group successfully!');
          } else {
            message.error('Cannot assign user(s) to group');
          }
        });
      } else {
        const deleteIdList = moveKeys?.map(item => {
          return assignedGroups?.find(el => el?.organizationId === item)?.id;
        });
        deleteRateGroupOrgMapping(deleteIdList).then(res => {
          if (res?.data?.deleteRateGroupOrganizationMappings) {
            setTargetKeys(newTargetKeys);
            message.success('Delete user(s) to group successfully!');
          } else {
            message.error('Cannot assign user(s) to group');
          }
        });
      }
    }
  };

  // = =========== render components ============== //

  const rateGroupItems = (): MenuProps['items'] => {
    const addNewMenu = getItem({
      label: 'Add New Group',
      key: 'addRateGroupBtn',
      icon: <FontAwesomeIcon icon={faPlusCircle} />,
      onClick: () => {
        handleAddRateGroup();
      },
    });
    const menuList = rateGroupList?.map(item => {
      let label = (
        <Tooltip
          key={item.id}
          color="gray"
          title={`${item?.name}(${item?.id})`}
        >
          {item?.name}
        </Tooltip>
      );
      if (editingRateGroup?.id === item?.id) {
        label = (
          <Input
            key={item.id}
            type="text"
            value={rateGroupName}
            placeholder="Add new group"
            onChange={handleRateGroupNameChange}
            onBlur={handleSaveRateGroup}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        );
      }

      return getItem({
        label,
        key: item.id,
        icon: (
          <div
            style={{
              position: 'absolute',
              right: '5px',
            }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{
                textAlign: 'center',
                marginTop: '12px',
              }}
              onClick={() => handleEditRateGroup(item)}
            />
            <Popconfirm
              placement="top"
              title="Are you sure to delete this group?"
              onConfirm={() => handleConfirm(item)}
              okText="Yes"
              cancelText="No"
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{
                  textAlign: 'center',
                  marginTop: '12px',
                  marginLeft: '12px',
                  color: 'red',
                }}
              />
            </Popconfirm>
          </div>
        ),
        onClick: () => {
          handleSetCurrentRateGroup(item);
        },
      });
    });
    return [addNewMenu, ...menuList];
  };

  return (
    <>
      {isAllow(userPermissions, 'create', ModuleTypes.Rate.value) ? (
        <Row justify="center">
          <Card
            title="Rate Group(s)"
            style={{
              width: 340,
              height: 460,
              marginRight: 20,
              overflowY: 'scroll',
            }}
          >
            <StyledMenu
              activeKey={currentRateGroup?.id}
              items={rateGroupItems()}
            />
          </Card>
          <Transfer
            dataSource={customerList}
            showSearch
            listStyle={{
              width: 340,
              height: 460,
            }}
            titles={['Customer List', 'Assigned Customers']}
            operations={['Assign', 'Remove']}
            targetKeys={targetKeys}
            onChange={handleChange}
            render={item => `${item?.title}-${item?.key}`}
            filterOption={(inputValue, option) =>
              option.title.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
        </Row>
      ) : (
        <Empty description={'No Permission'} />
      )}
    </>
  );
}

export default RateGroup;
