import { Button } from 'antd';
import WarningAbandon from 'components/common/WarningAbandon';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import React from 'react';

interface IProps {
  isAbandoning: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const FormUserActions = ({
  onSave,
  onCancel,
  isAbandoning,
  isLoading = false,
}: IProps) => {
  return (
    <div className="w-full px-4 py-3 border-t border-solid border-gray-200">
      <WithThemeProvider token={{ colorPrimary: '#fff' }}>
        <div className="flex justify-end">
          {isAbandoning ? <WarningAbandon /> : null}
          <Button
            onClick={onCancel}
            className={
              isAbandoning
                ? 'bg-gray-400 text-white hover:bg-red-600'
                : 'bg-gray-100 border-0 text-gray-900 hover:bg-gray-400'
            }
          >
            {isAbandoning ? 'Discard' : 'Cancel'}
          </Button>
          <Button
            onClick={onSave}
            loading={isLoading}
            className="ml-2 bg-mainOrange text-white hover:text-white hover:bg-mainOrangeHover"
          >
            Save
          </Button>
        </div>
      </WithThemeProvider>
    </div>
  );
};

export default FormUserActions;
