import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBuilding,
  faChartColumn,
  faChartSimple,
  faMap,
  faPlusCircle,
  faQuestionCircle,
  faStore,
  faTruckFast,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';

export const ERROR_MESSAGE = {
  NO_DATA_FOUND: 'No data found',
  LOADING_DATA_ERROR: 'There has been an error loading data',
};

export interface MenuItemType {
  index: number;
  value: string;
  label: string;
  icon?: IconProp;
  show: boolean;
  skipPermission: boolean;
  newTab: boolean;
  url?: string;
  onClick?: () => void;
  isBooking?: boolean;
}

export interface ParentMenuType extends MenuItemType {
  subMenu: MenuItemType[];
}

export const MENU_ITEMS: ParentMenuType[] = [
  {
    index: 0,
    value: 'booking',
    label: 'Booking',
    icon: faPlusCircle,
    show: true,
    skipPermission: true,
    newTab: false,
    url: '',
    subMenu: [
      {
        index: 0,
        show: true,
        newTab: false,
        label: 'New General Booking',
        skipPermission: true,
        value: 'freightBooking',
        isBooking: true,
      },
      {
        index: 1,
        show: true,
        newTab: false,
        label: 'New Container Booking',
        skipPermission: true,
        value: 'containerBooking',
        isBooking: true,
      },
    ],
  },
  {
    index: 1,
    value: 'dashboard',
    label: 'Dashboard',
    icon: faChartSimple,
    show: true,
    skipPermission: false,
    newTab: false,
    url: '',
    subMenu: [
      {
        index: 0,
        ...ModuleTypes.DashboardFinance,
        show: true,
        skipPermission: false,
        newTab: false,
      },
    ],
  },
  {
    index: 2,
    value: 'dispatch',
    label: 'Dispatch',
    icon: faTruckFast,
    show: true,
    skipPermission: false,
    url: '',
    newTab: false,
    subMenu: [
      {
        index: 0,
        ...ModuleTypes.Booking,
        show: true,
        skipPermission: false,
        newTab: false,
      },
    ],
  },
  {
    index: 3,
    value: 'financial',
    label: 'Financials',
    icon: faChartColumn,
    show: true,
    skipPermission: false,
    newTab: false,
    url: '',
    subMenu: [
      {
        index: 0,
        ...ModuleTypes.Surcharge,
        show: true,
        skipPermission: false,
        newTab: false,
      },
      {
        index: 1,
        ...ModuleTypes.Rate,
        show: true,
        skipPermission: false,
        newTab: false,
      },
      {
        index: 2,
        ...ModuleTypes.Faf,
        show: true,
        skipPermission: false,
        newTab: false,
      },
      {
        index: 3,
        ...ModuleTypes.Invoice,
        show: true,
        skipPermission: false,
        newTab: false,
      },

      {
        index: 4,
        ...ModuleTypes.Invoices,
        show: true,
        skipPermission: true,
        newTab: false,
      },
    ],
  },
  {
    index: 4,
    value: 'asset',
    label: 'Assets',
    icon: faStore,
    show: true,
    skipPermission: false,
    newTab: false,
    url: '',
    subMenu: [
      {
        index: 0,
        ...ModuleTypes.Vehicle,
        show: true,
        skipPermission: false,
        newTab: false,
      },
    ],
  },
  {
    index: 5,
    value: 'location',
    label: 'Locations',
    icon: faMap,
    show: true,
    skipPermission: false,
    newTab: false,
    url: '',
    subMenu: [
      {
        index: 0,
        ...ModuleTypes.Place,
        show: true,
        skipPermission: false,
        newTab: false,
      },
      {
        index: 2,
        ...ModuleTypes.Address,
        show: false,
        skipPermission: false,
        newTab: false,
      },
    ],
  },
  {
    index: 6,
    value: 'organization',
    label: 'Organizations',
    icon: faBuilding,
    show: true,
    skipPermission: false,
    newTab: false,
    url: '',
    subMenu: [
      {
        index: 0,
        ...ModuleTypes.Customer,
        show: true,
        skipPermission: false,
        newTab: false,
      },
      {
        index: 1,
        ...ModuleTypes.Division,
        show: true,
        skipPermission: false,
        newTab: false,
      },
    ],
  },

  {
    index: 8,
    value: 'security',
    label: 'Users & Groups',
    icon: faUsers,
    show: true,
    skipPermission: false,
    newTab: false,
    url: '',
    subMenu: [
      {
        index: 0,
        ...ModuleTypes.User,
        show: true,
        skipPermission: false,
        newTab: false,
      },
      {
        index: 1,
        ...ModuleTypes.Group,
        show: true,
        skipPermission: false,
        newTab: false,
      },
    ],
  },
  {
    index: 9,
    value: 'help',
    label: 'Help',
    icon: faQuestionCircle,
    url: 'https://icoslive.atlassian.net/wiki/spaces/IC/overview',
    newTab: true,
    skipPermission: true,
    show: true,
    subMenu: [],
  },
];
