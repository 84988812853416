import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { RateMenuType } from 'components/Workspace/defaultConfigs/types/rates';
import { FormHooksProvider } from 'context/FormHooksContext';
import useBookingRate from 'context/financials_rate/hooks';
import React, { useEffect } from 'react';
import useRateStore from '../store';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';

interface IProps {
  afterValidation?: (vehicle?: any) => void;
}

export default ({ afterValidation, ...rest }: IDialogTrigger & IProps) => {
  const formTitle = 'New RateLine';
  const [setIsSaving, isSaving] = useRateStore(state => [
    state.setIsSaving,
    state.isSaving,
  ]);
  const {
    createRate,
    createRateRateGroupMapping,
    modifyRate,
    copyRateLine,
    selectedMenu,
    selectedRate,
    rateLineList,
  } = useBookingRate();

  useEffect(() => {
    setIsSaving(false);
  }, []);

  async function _onSubmit(formData: any) {
    setIsSaving(true);
    onCreate(formData);
  }

  async function onCreate(formData: any) {
    // 1. Update rate : * isDeleted:true, endData:current date and time
    const updateRateData = {
      id: selectedRate?.id,
      endDate: new Date().toJSON(),
      isDeleted: true,
    };

    const qryModifyRate = await modifyRate(updateRateData);
    const {
      data: { modifyRate: resultModifyRate },
    } = qryModifyRate;

    // 2. Create rate : using the selected rate data, creates new rate
    if (resultModifyRate?.rate) {
      const rateData = {
        organizationId: selectedRate?.organizationId,
        startDate: selectedRate?.startDate,
        endDate: selectedRate?.endDate,
        serviceTypeId: selectedRate?.serviceTypeId,
        tonne: selectedRate?.tonne || 0,
        minimum: selectedRate?.minimum || 0,
        maximum: selectedRate?.maximum || 0,
        rd: selectedRate?.rd || 0,
        rateCommodityId: selectedRate?.rateCommodityId,
        detail: selectedRate?.detail,
        unitOfMeasureId: selectedRate?.unitOfMeasureId,
        addressFromId: selectedRate?.addressFromId,
        addressToId: selectedRate?.addressToId,
      };
      const qryAddRate = await createRate(rateData);
      const {
        data: { addRate: resultAddRate },
      } = qryAddRate;

      const onCreateRateGroupMapping = response => {
        if (response?.data?.addRateRateGroupMapping) {
          const { rateGroupMapping } = response.data.addRateRateGroupMapping;
          return rateGroupMapping
            ? null
            : {
                message: 'Cannot create rate for the rate group',
              };
        }

        return {
          message: 'Cannot create rate for the rate group',
        };
      };

      // 3. create rate lines:
      if (resultAddRate?.rate) {
        // 3.1 if its rateGroup, create RateRateGroupMapping
        if (selectedMenu?.type === RateMenuType.RATEGROUP) {
          const rateRateGroupMappingData = {
            rateId: resultAddRate.rate?.id,
            rateGroupId: selectedMenu?.id,
          };

          const res = await createRateRateGroupMapping(
            rateRateGroupMappingData,
          );

          onCreateRateGroupMapping(res);
        }

        // 3.2. prepare rate lines
        const oldRateLineData = rateLineList?.nodes?.map(rateLine => {
          return {
            breakPoint1: rateLine.breakPoint1 ? rateLine?.breakPoint1 : 0,
            charge: rateLine?.charge ? rateLine?.charge : 0,
            cost: rateLine?.cost ? rateLine?.cost : 0,
            rateType: rateLine?.rateType,
            rateId: resultAddRate.rate?.id,
          };
        });

        const newRateLineData = formData?.addRateLines?.map(rateLine => {
          return {
            breakPoint1: rateLine.breakPoint1 ? rateLine?.breakPoint1 : 0,
            charge: rateLine?.charge ? rateLine?.charge : 0,
            cost: rateLine?.cost ? rateLine?.cost : 0,
            rateType: rateLine?.rateType,
            rateId: resultAddRate.rate?.id,
          };
        });

        // 3.3. create line lines
        const qryRateLine = await copyRateLine([
          ...oldRateLineData,
          ...newRateLineData,
        ]);
        const {
          data: { addRateLines: resultRateLine },
        } = qryRateLine;
        if (resultRateLine?.some(el => el?.rateLine)) {
          afterValidation?.(resultRateLine);
          message.success(`Rate Lines created`);
        } else {
          message.error('Cannot create rate lines');
        }
      }
    } else {
      message.error('Something went wrong ...');
    }
  }

  return (
    <FormHooksProvider resolver={schemaResolver}>
      <MainForm
        {...rest}
        title={formTitle}
        onSubmit={_onSubmit}
        isSaving={isSaving}
      />
    </FormHooksProvider>
  );
};
