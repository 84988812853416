import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationUserFieldsFragment } from 'api/graphql/generated/serviceTypesAndHooks';
import { AzureUser } from './types';

export interface AuthState {
  azureUser: AzureUser;
  switchAccounts: [AuthenticationUserFieldsFragment];
  currentAccount: AuthenticationUserFieldsFragment;
  userPermissions: [];
  modulePermissions: [];
}
export const initialState: AuthState = {
  azureUser: null,
  switchAccounts: null,
  currentAccount: null,
  userPermissions: [],
  modulePermissions: [],
};

const storeSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAzureUser: (state, action: PayloadAction<any>) => {
      const { azureUser, currentAccount } = action.payload;
      state.azureUser = azureUser;
      state.currentAccount = currentAccount;
    },
    setCurrentAccount: (state, action: PayloadAction<any>) => {
      state.switchAccounts = action.payload.switchAccounts;
      state.currentAccount = action.payload.currentAccount;
    },
    setUserPermissions: (state, action: PayloadAction<any>) => {
      state.userPermissions = action.payload.userPermissions;
      state.modulePermissions = action.payload.modulePermissions;
    },
  },
});

export const selectAzureUser = state => state.authentication.azureUser;
export const selectCurrentAccount = state =>
  state.authentication.currentAccount;
export const selectUserGroupMappings = state =>
  state.authentication.userGroupMappings;

export const selectSwitchAccounts = state =>
  state.authentication.switchAccounts;
export const selectUserPermissions = state =>
  state.authentication.userPermissions;
export const selectModulePermissions = state =>
  state.authentication.modulePermissions;

export const { actions, reducer, name: sliceKey } = storeSlice;
