import { Input } from 'antd';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { useFormHooks } from 'context/FormHooksContext';
import React from 'react';

export default function DetailsForm() {
  const { control } = useFormHooks();

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="Division Name">
        <WithFormHooksController
          name="name"
          control={control}
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
    </div>
  );
}

const Field = ({
  label,
  children,
  span = 12,
}: {
  label: string;
  children: React.ReactNode;
  span?: number;
}) => (
  <div
    className="flex flex-col"
    // tailwindcss can't interpolate dynamic style at runtime
    // so we have to use inline style.
    // See https://v2.tailwindcss.com/docs/just-in-time-mode
    style={{ gridColumn: `span ${span} / span ${span}` }}
  >
    <span className="text-default text-[.8rem] truncate font-medium">
      {label}
    </span>
    {children}
  </div>
);
