import {
  BookingAttributeTemplateFieldsFragment,
  GetContainerVisitResponse,
  useAcceptBookingsMutation,
  useAddDocumentMutation,
  useAllocateBookingsMutation,
  useCancelBookingsMutation,
  useCreateBookingMutation,
  useDeleteDocumentMutation,
  useDeliverBookingMutation,
  useDuplicateBookingMutation,
  useGetBookingAttributeTemplateLazyQuery,
  useGetBookingByIdLazyQuery,
  useGetBookingTemplateLazyQuery,
  useGetBookingsLazyQuery,
  useGetContainerInfoLazyQuery,
  useModifyBookingMutation,
  useOffloadBookingsMutation,
  usePOdBookingMutation,
  useReAllocateBookingsMutation,
  useSetDatesMutation,
  useUnAllocateBookingsMutation,
  useUpliftBookingsMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import useAuthentication from '../security_authentication/hook';
import {
  actions,
  reducer,
  selectBookingAttributeTemplate,
  selectBookingAttributes,
  selectBookingDetails,
  selectContainerInfo,
  selectFlagRows,
  selectRecordTemplate,
  selectSelectedItems,
  selectSubModals,
  selectWatchNewBookingPlace,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useBookings() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const bookingWorkspaceData = useSelector(selectWorkspaceData);
  const bookingDetails = useSelector(selectBookingDetails);
  const bookingAttributeTemplate: BookingAttributeTemplateFieldsFragment = useSelector(
    selectBookingAttributeTemplate,
  );
  const { currentAccount } = useAuthentication();
  const recordTemplate = useSelector(selectRecordTemplate);
  const containerInfo: [GetContainerVisitResponse] = useSelector(
    selectContainerInfo,
  );
  const bookingAttributes = useSelector(selectBookingAttributes);

  const subModals = useSelector(selectSubModals);
  const selectedItems = useSelector(selectSelectedItems);
  const flagRows = useSelector(selectFlagRows);
  const newBookingPlaceWatching = useSelector(selectWatchNewBookingPlace);

  const handleCustomAttributesData = data => {
    const customAttributes = data?.customAttributes?.map(customAtt => {
      const foundAttributeTemplate = bookingAttributeTemplate?.bookingAttributes?.find(
        att => att?.id === customAtt?.attributeId,
      );

      let newValues = foundAttributeTemplate?.values;

      if (newValues?.length > 0) {
        newValues = newValues?.map(value => {
          if (customAtt?.valueEnumName === value?.name) {
            return {
              ...value,
              isPreSelected: true,
            };
          }
          return {
            ...value,
            isPreSelected: false,
          };
        });
      }
      return {
        ...foundAttributeTemplate,
        ...customAtt,
        values: newValues,
        defaultValue: customAtt?.valueString || customAtt?.valueEnumName,
        attributeId: customAtt?.valueEnumId,
        displayOnBooking: true,
      };
    });

    return { ...data, customAttributes };
  };

  const [getBookingByIdQuery] = useGetBookingByIdLazyQuery({
    onCompleted: response => {
      if (response?.bookingById) {
        const data = handleCustomAttributesData(response?.bookingById);
        dispatch(
          actions.setBookingDetails({
            itemDetails: data,
          }),
        );
      }
    },
  });

  const getBookingById = async bookingId => {
    getBookingByIdQuery({
      variables: { bookingId },
    });
  };

  const resetItemDetails = () => {
    dispatch(
      actions.setBookingDetails({
        itemDetails: null,
      }),
    );
  };

  const [
    getBookingListQuery,
    { loading: pendinggetBookingList },
  ] = useGetBookingsLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.dispatchBookingSummary?.nodes) {
        const nodes = response?.dispatchBookingSummary?.nodes;
        let newList = nodes;
        if (
          bookingWorkspaceData?.nodes?.length > 0 &&
          response.dispatchBookingSummary.pageInfo.hasPreviousPage
        ) {
          newList = [...(bookingWorkspaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.dispatchBookingSummary,
          nodes: newList,
        };

        dispatch(
          actions.setWorkspaceData({
            workspaceData: { ...data, loading: false, error: null },
          }),
        );
      }
    },
    onError: () => {
      dispatch(
        actions.setWorkspaceData({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setWorkspaceData({
        workspaceData: null,
      }),
    );
  };

  const getBookingList = ({ divisionIds, filters, cursor, order }) => {
    return getBookingListQuery({
      variables: {
        divisionIds,
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    getBookingAttributeTemplateQuery,
    { loading: pendingGetBookingAttributeTemplate },
  ] = useGetBookingAttributeTemplateLazyQuery({
    onCompleted: response => {
      if (response?.bookingDetails) {
        dispatch(
          actions.setBookingAttributeTemplate({
            bookingAttributeTemplate: response?.bookingDetails,
            loading: false,
          }),
        );
      }
    },
  });

  const getBookingAttributeTemplate = organizationId => {
    getBookingAttributeTemplateQuery({
      variables: {
        organizationId,
        bookingTypeIds: [1, 2],
      },
    });
  };

  const [
    getBookingTemplateQuery,
    { loading: pendingGetBookingTemplate },
  ] = useGetBookingTemplateLazyQuery({
    onCompleted: response => {
      if (response?.bookingDetails) {
        dispatch(
          actions.setRecordTemplate({
            recordTemplate: response?.bookingDetails,
            loading: false,
          }),
        );
      }
    },
  });

  const getBookingTemplate = (organizationId, bookingTypeIds) => {
    getBookingTemplateQuery({
      variables: {
        organizationId,
        bookingTypeIds,
      },
    });
  };

  const [
    getContainerInfoQuery,
    { loading: pendingGetContainerInfo },
  ] = useGetContainerInfoLazyQuery({
    onCompleted: response => {
      if (response?.containerVisitsByContainerNumbers) {
        dispatch(
          actions.setContainerInfo({
            containerInfo: response?.containerVisitsByContainerNumbers,
            loading: false,
          }),
        );
      }
      return undefined;
    },
  });

  const getContainerInfo = (containerNumbers, portCode) => {
    const organizationId =
      currentAccount?.organizationOrganizationMapping?.parentOrganizationId;
    getContainerInfoQuery({
      variables: {
        containerNumbers,
        organizationId,
        portCode,
      },
    });
  };

  const resetContainerInfo = () => {
    dispatch(
      actions.setContainerInfo({
        containerInfo: null,
        loading: false,
      }),
    );
  };

  const [
    createBookingMutation,
    { loading: pendingCreateBooking },
  ] = useCreateBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const [
    duplicateBookingMutation,
    { loading: pendingDuplicateBooking },
  ] = useDuplicateBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createBooking = (overrideDocket, input) => {
    return createBookingMutation({
      variables: { overrideDocket, input },
    });
  };

  const duplicateBookings = (bookingId, numDuplicates) => {
    return duplicateBookingMutation({
      variables: {
        bookingId,
        numDuplicates,
      },
    });
  };

  const [
    modifyBookingMutation,
    { loading: pendingModifyBooking },
  ] = useModifyBookingMutation({
    onCompleted: response => {
      if (
        response?.modifyBookings?.modifyBookingPayloadOutputModel
          ?.validBookingViews
      ) {
        setUpdatedItem([
          response?.modifyBookings?.modifyBookingPayloadOutputModel
            ?.validBookingViews[0],
        ]);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyBooking = async input => {
    return modifyBookingMutation({
      variables: { input },
    });
  };

  const [
    allocateBookingsMutation,
    { loading: pendingAllocateBooking },
  ] = useAllocateBookingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const allocateBooking = (bookingIds: [number], assetId: number) => {
    return allocateBookingsMutation({
      variables: {
        bookingIds,
        assetId,
      },
    });
  };

  const [
    reAllocateBookingMutation,
    { loading: pendingReAllocateBooking },
  ] = useReAllocateBookingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const reAllocateBooking = (bookingIds: [number], assetId: number) => {
    return reAllocateBookingMutation({
      variables: {
        bookingIds,
        assetId,
      },
    });
  };

  const [
    acceptBookingMutation,
    { loading: pendingAcceptBooking },
  ] = useAcceptBookingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const acceptBooking = (bookingIds: [number]) => {
    return acceptBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    upliftBookingMutation,
    { loading: pendingUpliftBooking },
  ] = useUpliftBookingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const upliftBooking = (bookingIds: [number]) => {
    return upliftBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    offloadBookingMutation,
    { loading: pendingOffloadBooking },
  ] = useOffloadBookingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const offloadBooking = (bookingIds: [number]) => {
    return offloadBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    unallocateBookingMutation,
    { loading: pendingUnallocateBooking },
  ] = useUnAllocateBookingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const unAllocateBooking = (bookingIds: [number]) => {
    return unallocateBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    cancelBookingMutation,
    { loading: pendingCancelBooking },
  ] = useCancelBookingsMutation({
    onCompleted: response => {
      const updatedItems = response?.cancelBookings?.bookingViews;
      setUpdatedItem(updatedItems);
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const cancelBooking = (bookingIds: [number]) => {
    return cancelBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    deliverBookingMutation,
    { loading: pendingDeliverBooking },
  ] = useDeliverBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deliverBooking = input => {
    return deliverBookingMutation({
      variables: { input },
    });
  };
  const [
    pODBookingMutation,
    { loading: pendingPODBooking },
  ] = usePOdBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });
  const pODBooking = input => {
    return pODBookingMutation({
      variables: { input },
    });
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  const setFlagRows = rows => {
    dispatch(
      actions.setFlagRows({
        flagRows: rows,
      }),
    );
  };

  const showSubModal = content => {
    dispatch(actions.setSubModal(content));
  };

  const setNewItems = newItems => {
    const refinedNewItems = newItems.map(item => {
      return handleCustomAttributesData(item);
    });
    const oldList = bookingWorkspaceData?.nodes || [];
    const newList = [...refinedNewItems, ...oldList];
    const data = {
      ...bookingWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewBookings({
        newItems,
        workspaceData: data,
        flagRows: newItems,
      }),
    );
  };

  const setUpdatedItem = updatedItems => {
    const newList = bookingWorkspaceData?.nodes?.map(item => {
      const uitem = updatedItems.find(x => x.id === item.id);
      if (uitem) {
        const formattedUitem = handleCustomAttributesData(uitem);
        return { ...item, ...formattedUitem };
      }
      return item;
    });

    const data = {
      ...bookingWorkspaceData,
      nodes: newList,
    };

    dispatch(
      actions.setUpdatedBooking({
        updatedItems,
        workspaceData: data,
        flagRows: updatedItems,
      }),
    );
  };

  const setOnlyUpdateBookingItems = updatedItems => {
    dispatch(
      actions.setOnlyUpdateBookingItems({
        updatedItems,
      }),
    );
  };

  const setUpdatedItemBySignalR = updatedItems => {
    const newList = bookingWorkspaceData?.nodes?.map(item => {
      const uitem = updatedItems.find(x => x.id === item.id);
      if (uitem) {
        return { ...item, ...uitem };
      }
      return item;
    });
    const data = {
      ...bookingWorkspaceData,
      nodes: newList,
    };

    dispatch(
      actions.setUpdatedBookingBySignalR({
        workspaceData: data,
        flagRows: updatedItems,
      }),
    );
  };
  const setNewItemBySignalR = newItems => {
    const oldList = bookingWorkspaceData?.nodes || [];
    const newList = [...newItems, ...oldList];
    const data = {
      ...bookingWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewBookingBySignalR({
        workspaceData: data,
        flagRows: newItems,
      }),
    );
  };

  const watchNewBookingPlace = (isWatching, newPlace, placeOrder) => {
    dispatch(
      actions.setWatchNewBookingPlace({
        isWatching,
        newPlace,
        placeOrder,
      }),
    );
  };

  const [setDatesMutation, { loading: pendingSetDates }] = useSetDatesMutation({
    onCompleted: response => {
      const updatedItems = response?.setDates?.bookingViews;
      setUpdatedItem(updatedItems);
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const setDates = input => {
    return setDatesMutation({
      variables: {
        input,
      },
    });
  };

  const [
    addDocumentMutation,
    { loading: pendingAddDocument },
  ] = useAddDocumentMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const addDocument = (bookingId, file, documentTypeId) => {
    return addDocumentMutation({
      variables: {
        bookingId,
        file,
        documentTypeId,
      },
    });
  };

  const [
    deleteDocumentMutation,
    { loading: pendingDeleteDocument },
  ] = useDeleteDocumentMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteDocument = documentId => {
    return deleteDocumentMutation({
      variables: {
        documentId,
      },
    });
  };

  return {
    refreshWorkspaceData,
    resetContainerInfo,
    resetItemDetails,
    getBookingList,
    getBookingById,
    createBooking,
    duplicateBookings,
    modifyBooking,
    getBookingTemplate,
    getContainerInfo,
    getBookingAttributeTemplate,
    allocateBooking,
    acceptBooking,
    upliftBooking,
    offloadBooking,
    deliverBooking,
    reAllocateBooking,
    unAllocateBooking,
    cancelBooking,
    pODBooking,
    showSubModal,
    setSelectedItems,
    setUpdatedItem,
    setOnlyUpdateBookingItems,
    setUpdatedItemBySignalR,
    setNewItemBySignalR,
    setNewItems,
    setFlagRows,
    watchNewBookingPlace,
    setDates,
    addDocument,
    deleteDocument,
    newBookingPlaceWatching,
    bookingAttributes,
    pendingGetBookingAttributeTemplate,
    containerInfo,
    recordTemplate,
    bookingAttributeTemplate,
    pendingGetBookingTemplate,
    pendingGetContainerInfo,
    bookingWorkspaceData,
    bookingDetails,

    pendingCreateBooking,
    pendingDuplicateBooking,
    pendingModifyBooking,
    pendinggetBookingList,
    pendingAllocateBooking,
    pendingAcceptBooking,
    pendingDeliverBooking,
    pendingUpliftBooking,
    pendingOffloadBooking,
    pendingReAllocateBooking,
    pendingUnallocateBooking,
    pendingCancelBooking,
    pendingPODBooking,
    pendingAddDocument,
    subModals,
    selectedItems,
    flagRows,
    pendingSetDates,
    pendingDeleteDocument,
  };
}
