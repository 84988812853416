import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';
import useBookingRate from 'context/financials_rate/hooks';
import useRateLineGridConfig from './rateLinesGridConfig';
import useRateGridConfig from './ratesGridConfig';

export default function useRaterConfig() {
  const {
    getOrgList,
    getRateGroupList,
    getFafGroupList,
    getRates,
    getRateLines,
    getRateRateGroupMapping,
  } = useBookingRate();
  const { rateLinesGridConfig } = useRateLineGridConfig();
  const { ratesGridConfig } = useRateGridConfig();

  const config = {
    title: 'Rate',
    id: 'rate-list',
    direction: 'horizontal',
    defaultActiveKey: ['rate', 'rateLine'],
    module: ModuleTypes.Rate,
    getOrgList: query => getOrgList(query),
    getRateGroupList: query => getRateGroupList(query),
    getFafGroupList: query => getFafGroupList(query),
    getRates: query => getRates(query),
    getRateLines: query => getRateLines(query),
    getRateRateGroupMapping: query => getRateRateGroupMapping(query),
  };
  const detailConfig = {
    rate: {
      id: 'rate',
      header: 'Rates',
      gridConfg: ratesGridConfig,
      initGridHeight: `30vh`,
      newRecordValues: {
        id: undefined,
        apiKey: '',
        baseURL: '',
        region: 'NZ',
        status: 22,
        version: '',
      },
    },
    rateLine: {
      id: 'rateLine',
      header: 'Rate Lines',
      initGridHeight: `45vh`,
      gridConfg: rateLinesGridConfig,
      activeAddBtn: true,
    },
  };

  return {
    config,
    detailConfig,
  };
}
