import { Layout } from 'antd';
import GeneralContainer from 'components/common/GeneralContainer';
import useApp from 'context/app/hooks';
import { actions as appActions, selectDialog } from 'context/app/slice';
import useBookings from 'context/bookings/hooks';
import useGenericCodeLists from 'context/genericCodeLists/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { actions } from 'context/workspace/slice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormDialog from '../Dialog';
import HeaderBar from '../Navigation/HeaderBar';
import RightSidebar from '../Navigation/RightSidebar';
import Sidebar from '../Navigation/Sidebar';
import ErrorBoundary from '../common/ErrorBoundary';

const { Content } = Layout;

type Props = React.PropsWithChildren<{
  module?: string;
  screenViews?: boolean;
}>;

const WithAuthentication = (props: Props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { closeDialog } = useApp();

  const { getBookingAttributeTemplate } = useBookings();
  const { getGenericCodeLists } = useGenericCodeLists();

  const {
    azureUser,
    modulePermissions,
    currentAccount,
    userAuthenticate,
    getCurrentUser,
    getUserPermissions,
    setAzureUser,
  } = useAuthentication();
  const isAdmin = currentAccount?.userTypeId === 19;
  const hasPermission = modulePermissions?.includes(props.module) || isAdmin;
  const [pending, setPending] = useState(true);
  const dialog = useSelector(selectDialog);
  const [collapsed, setCollapsed] = useState(false);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    // will replace workspace by screenConfig
    dispatch(
      appActions.setScreenConfig({
        screenConfig: {
          name: props.module,
          screenViews: props.screenViews,
        },
      }),
    );
  }, [props.module]);

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem('icosAuth'));
    if (authToken) {
      userAuthenticate(authToken).then(user => {
        if (user) {
          setAzureUser(user);
        } else {
          setAzureUser(null);
          history('/signIn');
        }
      });
    } else {
      setAzureUser(null);
      history('/signIn');
    }
  }, []);

  useEffect(() => {
    if (azureUser) {
      getCurrentUser();
    }
  }, [azureUser]);

  useEffect(() => {
    if (currentAccount) {
      const orgId =
        currentAccount?.organizationOrganizationMapping?.parentOrganizationId;
      getUserPermissions(currentAccount);
      getBookingAttributeTemplate(orgId);
      getGenericCodeLists({ filters: null, order: { name: 'ASC' } });
      setPending(false);
    }
  }, [currentAccount]);

  //= =============================== render components ======================================//
  const formDialog = dialog.params ? (
    <FormDialog {...dialog} onClose={closeDialog} />
  ) : null;

  const renderContent = () => {
    if (hasPermission) {
      return <>{props.children}</>;
    }
    dispatch(
      actions.setWorkspace({
        workspace: null,
      }),
    );

    return <>Unauthorized Access</>;
  };

  if (pending) {
    return <GeneralContainer type="loading" />;
  }
  return (
    <Layout>
      <Sidebar collapsed={collapsed} />
      <Layout className="bg-white">
        <HeaderBar collapsed={collapsed} onToggle={handleToggle} />
        <RightSidebar />
        <ErrorBoundary>
          <Content>
            {renderContent()}
            {formDialog}
          </Content>
        </ErrorBoundary>
      </Layout>
    </Layout>
  );
};

export default WithAuthentication;
