import {
  faArrowsToDot,
  faBan,
  faBoxesPacking,
  faCalendarDay,
  faCheckCircle,
  faCopy,
  faFileCsv,
  faFileExcel,
  faPlay,
  faShuffle,
  faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CsvExportParams, ExcelExportParams } from 'ag-grid-community';
import { Menu, MenuProps, message } from 'antd';
import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';
import useBookings from 'context/bookings/hooks';
import useAuthentication from 'context/security_authentication/hook';
import {
  DispatchAction,
  dispatchActionValidator,
} from 'helpers/dispatchActionValidator';
import React from 'react';

interface CustomContextMenuProps {
  x: number;
  y: number;
  onClose: () => void;
}

const CustomContextMenu: React.FC<CustomContextMenuProps> = ({
  x,
  y,
  onClose,
}) => {
  const {
    selectedItems,
    upliftBooking,
    acceptBooking,
    offloadBooking,
    cancelBooking,
    unAllocateBooking,
    showSubModal,
    setUpdatedItem,
  } = useBookings();

  const { isAllow } = useAuthentication();

  const handleCopy = () => {
    window.$gridApi.copySelectedRangeToClipboard();
    onClose();
  };

  const handleCopyWithHeader = () => {
    window.$gridApi.copySelectedRangeToClipboard({ includeHeaders: true });
    onClose();
  };

  const handleExportExcel = () => {
    const excelParams: ExcelExportParams = {
      fileName: 'Exported_Booking_Data.xlsx',
      onlySelected: true,
    };
    window.$gridApi.exportDataAsExcel(excelParams);
    onClose();
  };

  const handleExportCSV = () => {
    const csvParams: CsvExportParams = {
      fileName: 'Exported_Booking_Data.csv',
      onlySelected: true,
    };
    window.$gridApi.exportDataAsCsv(csvParams);
    onClose();
  };

  const handleAllocate = () => {
    const actionValidator = dispatchActionValidator('allocate', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator?.message);
      return;
    }
    showSubModal({
      name: 'selectVehicle',
      visible: true,
    });
    onClose();
  };

  const handleReAllocate = () => {
    const actionValidator = dispatchActionValidator(
      'reallocate',
      selectedItems,
    );
    if (actionValidator?.message) {
      message.error(actionValidator?.message);
      return;
    }
    showSubModal({
      name: 'reSelectVehicle',
      visible: true,
    });
    onClose();
  };

  const handleSetDates = () => {
    showSubModal({
      name: 'setDates',
      visible: true,
    });
    onClose();
  };

  const handleUpdateItems = (result, action) => {
    if (result?.length > 0) {
      setUpdatedItem(result);
      message.success(`${action} bookings successfully!`);
    } else {
      message.error(`Cannot ${action} bookings`);
    }
  };

  const handleAcceptBookings = () => {
    const actionValidator = dispatchActionValidator('accept', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id);
    acceptBooking(bookingIds).then(res => {
      const successItems = res?.data?.acceptBookings?.bookingViews;
      handleUpdateItems(successItems, 'Accept');
    });
    onClose();
  };
  const handleUpliftBookings = () => {
    const actionValidator = dispatchActionValidator('uplift', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id);
    upliftBooking(bookingIds).then(res => {
      const successItems = res?.data?.upliftBookings?.bookingViews;
      handleUpdateItems(successItems, 'Uplift');
    });
    onClose();
  };
  const handleOffloadBookings = () => {
    const actionValidator = dispatchActionValidator('offload', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id);
    offloadBooking(bookingIds).then(res => {
      const successItems = res?.data?.offloadBookings?.bookingViews;
      handleUpdateItems(successItems, 'Offload');
    });
    onClose();
  };

  const handleUnallocateBookings = () => {
    const actionValidator = dispatchActionValidator(
      'unallocate',
      selectedItems,
    );
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id);
    unAllocateBooking(bookingIds).then(res => {
      const successItems = res?.data?.unallocateBookings?.bookingViews;
      handleUpdateItems(successItems, 'Unallocate');
    });
    onClose();
  };
  const handleCancelBookings = () => {
    const actionValidator = dispatchActionValidator('cancel', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id);
    cancelBooking(bookingIds).then(res => {
      const successItems = res?.data?.cancelBookings?.bookingViews;
      handleUpdateItems(successItems, 'Cancel');
    });
    onClose();
  };
  const exportMenuItems: MenuProps['items'] = [
    {
      key: 'exportExcel',
      label: 'Export Excel',
      icon: <FontAwesomeIcon icon={faFileExcel} />,
      onClick: () => handleExportExcel(),
    },
    {
      key: 'exportCSV',
      label: 'Export CSV',
      icon: <FontAwesomeIcon icon={faFileCsv} />,
      onClick: () => handleExportCSV(),
    },
  ];

  const actionMenuItems: MenuProps['items'] = [
    {
      key: 'update',
      label: 'Set Dates',
      icon: <FontAwesomeIcon icon={faCalendarDay} />,
      onClick: handleSetDates,
    },
    {
      key: 'allocate',
      label: 'Allocate',
      icon: <FontAwesomeIcon icon={faArrowsToDot} />,
      onClick: handleAllocate,
    },
    {
      key: 'accept',
      label: 'Accept',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      onClick: () => handleAcceptBookings(),
    },
    {
      key: 'uplift',
      label: 'Uplift',
      icon: <FontAwesomeIcon icon={faBoxesPacking} />,
      onClick: () => handleUpliftBookings(),
    },
    {
      key: 'offload',
      label: 'Offload',
      icon: <FontAwesomeIcon icon={faTruckRampBox} />,
      onClick: () => handleOffloadBookings(),
    },
    {
      key: 'reallocate',
      label: 'Reallocate',
      icon: <FontAwesomeIcon icon={faShuffle} />,
      onClick: () => handleReAllocate(),
    },
    {
      key: 'unallocate',
      label: 'Unallocate',
      icon: <FontAwesomeIcon icon={faFileCsv} />,
      onClick: () => handleUnallocateBookings(),
    },
    {
      key: 'cancel',
      label: 'Cancel',
      icon: <FontAwesomeIcon icon={faBan} />,
      onClick: () => handleCancelBookings(),
    },
  ];

  const items: MenuProps['items'] = [
    {
      key: 'copy',
      label: 'Copy',
      icon: <FontAwesomeIcon icon={faCopy} />,
      onClick: () => handleCopy(),
    },
    {
      key: 'copyH',
      label: 'Copy with Headers',
      icon: <FontAwesomeIcon icon={faCopy} />,
      onClick: () => handleCopyWithHeader(),
    },
    {
      key: 'actions',
      label: 'Actions',

      disabled: !selectedItems?.length,
      icon: <FontAwesomeIcon icon={faPlay} />,
      children:
        isAllow('update', ModuleTypes.Booking.value) &&
        actionMenuItems.filter(item => {
          const action = item?.key as DispatchAction;
          return !dispatchActionValidator(action, selectedItems)?.message;
        }),
    },
    {
      key: 'export',
      label: 'Export',
      icon: <FontAwesomeIcon icon={faCopy} />,
      children: exportMenuItems,
    },
  ];

  return (
    <div
      style={{
        position: 'absolute',
        left: x,
        top: y,
        background: 'white',
        boxShadow: '0px 0px 5px 2px #888888',
      }}
    >
      <Menu mode="vertical" items={items} />
    </div>
  );
};

export default CustomContextMenu;
