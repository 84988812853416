import {
  faCheckCircle,
  faInfoCircle,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Descriptions,
  Image,
  Input,
  Popover,
  Row,
  Select,
  message,
} from 'antd';
import { BookingAttributeModel } from 'api/graphql/generated/serviceTypesAndHooks';
import pcLogo from 'assets/PortConnectLogo.png';
import Modal from 'components/Modal/index';
import { PortCodeList } from 'components/Workspace/defaultConfigs/types/booking';
import useBookings from 'context/bookings/hooks';
import { mappedContainerInfo } from 'helpers/containerInfoHandlers';
import { convertedCustomeAttributeKeyValuePair } from 'helpers/customAttributeHandlers';
import { uid } from 'helpers/uid';
import React, { useEffect, useState } from 'react';
import { StyledSubmitButton } from '../styles';

type Props = {
  visible: boolean;
  onClose: () => void;
};

type MappedDataType = {
  [key: string]: {
    containerNumber: string;
    containerInfo: any;
  };
};

function UpDatePortInfo(props: Readonly<Props>) {
  const { visible, onClose } = props;
  const {
    selectedItems,
    containerInfo,
    pendingGetContainerInfo,
    bookingAttributeTemplate,
    getContainerInfo,
    resetContainerInfo,
    modifyBooking,
    setUpdatedItem,
  } = useBookings();

  const [mappedData, setMappedData] = useState({} as MappedDataType);
  const [selectedPortCode, setSelectedPortCode] = useState(null);

  const handleClose = () => {
    resetContainerInfo();
    onClose();
  };

  const handleChangePortCode = value => {
    setSelectedPortCode(value);
  };

  const handleContainerNoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    bookingId,
  ) => {
    setMappedData({
      ...mappedData,
      [bookingId]: {
        containerNumber: e.currentTarget.value,
      },
    });
  };

  const handleGetPortInfo = () => {
    let containerNumbers = [];
    Object.keys(mappedData).forEach(key => {
      containerNumbers = [...containerNumbers, mappedData[key].containerNumber];
    });
    getContainerInfo(containerNumbers, selectedPortCode);
  };

  const handleSaveBooking = async () => {
    const inputData = Object.keys(mappedData)?.flatMap(key => {
      if (mappedData[key]?.containerInfo) {
        const bookingData = mappedData[key]?.containerInfo;
        const bookingAttributes = bookingAttributeTemplate?.bookingAttributes as [
          BookingAttributeModel,
        ];
        const bookingAttributesIds = convertedCustomeAttributeKeyValuePair(
          bookingAttributes,
          bookingData,
        );
        return {
          id: parseInt(key, 10),
          bookingTypeId: 2,
          bookingAttributesIds,
        };
      }
      return [];
    });

    const result = await modifyBooking(inputData);
    if (result?.data?.modifyBookings) {
      const updatedBookings = result?.data?.modifyBookings?.modifyBookingPayloadOutputModel?.validBookingViews?.flatMap(
        item => {
          if (item) {
            return item;
          }
          return [];
        },
      );
      message.success('Updating Booking(s) successfully!');
      setUpdatedItem(updatedBookings);
      onClose();
    }
  };

  useEffect(() => {
    if (containerInfo) {
      let result = mappedData;
      containerInfo?.forEach(item => {
        const containerNumber = item?.containerNumber;
        const bookingAttributes = bookingAttributeTemplate?.bookingAttributes as [
          BookingAttributeModel,
        ];
        Object.keys(mappedData).forEach(key => {
          if (mappedData[key].containerNumber === containerNumber) {
            const portInfo = mappedContainerInfo(item, bookingAttributes);
            result = {
              ...result,
              [key]: {
                ...mappedData[key],
                containerInfo: {
                  ...portInfo,
                  ContainerDoorSide: 24,
                  ContainerLoadType: 26,
                },
              },
            };
          }
        });
        setMappedData(result);
      });
    }
  }, [containerInfo]);

  useEffect(() => {
    if (selectedItems) {
      let mappedItems = {};
      selectedItems.forEach(item => {
        mappedItems = {
          ...mappedItems,
          [item?.id]: {
            containerNumber: '',
          },
        };
      });
      setMappedData(mappedItems);
    }
  }, [selectedItems]);

  const renderBody = () => {
    return (
      <Row justify="center">
        <Col span={24}>
          {Object.keys(mappedData)?.map(key => (
            <Row key={uid()} style={{ marginBottom: 10 }} gutter={[12, 12]}>
              <Col span={6}>
                <span>BookingID: {key}</span>
              </Col>
              <Col span={14}>
                <Input
                  placeholder="Container number"
                  value={mappedData[key].containerNumber}
                  onChange={e => handleContainerNoChange(e, key)}
                />
              </Col>
              <Col span={4}>
                {containerInfo ? (
                  <>
                    {mappedData[key]?.containerInfo ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            fontSize: '16px',
                            marginRight: 5,
                            color: 'green',
                          }}
                        />
                        <Popover
                          overlayStyle={{ width: '60%' }}
                          title="Container Info"
                          content={() =>
                            renderPortInfoContent(
                              mappedData[key]?.containerInfo,
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{ fontSize: '16px', color: 'blue' }}
                          />
                        </Popover>
                      </>
                    ) : (
                      <Popover
                        overlayStyle={{ width: 200 }}
                        title="Container Info"
                        content={<div>No Container Info Found</div>}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ fontSize: '16px' }}
                        />
                      </Popover>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
          ))}
          {renderPortCodeSelect()}
          {renderButtons()}
        </Col>
      </Row>
    );
  };

  const renderPortInfoContent = content => (
    <Descriptions
      title={content.containerNumber}
      column={2}
      size="small"
      layout="horizontal"
    >
      {Object.keys(content).map(key => (
        <Descriptions.Item label={key} key={key}>
          {content[key]?.toString()}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );

  const renderPortCodeSelect = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col span={6}>
          <span>Port Code</span>
        </Col>
        <Col span={14}>
          <Select
            options={PortCodeList}
            placeholder="Select Port Code"
            style={{ width: '100%' }}
            value={selectedPortCode}
            onChange={handleChangePortCode}
          />
        </Col>
      </Row>
    );
  };

  const renderButtons = () => {
    let disabledCall = false;
    let disabledSave = true;
    if (mappedData) {
      disabledCall =
        Object.values(mappedData).every(x => x?.containerNumber === '') ||
        !selectedPortCode;
      disabledSave = Object.values(mappedData).every(x => !x?.containerInfo);
    }
    return (
      <Row justify="center">
        <Col>
          <Button
            disabled={disabledCall}
            loading={pendingGetContainerInfo}
            icon={
              <Image preview={false} width={30} src={pcLogo} alt="pc logo" />
            }
            onClick={handleGetPortInfo}
          >
            <span style={{ marginLeft: 5 }}>Get Port Info</span>
          </Button>
          <StyledSubmitButton
            disabled={disabledSave}
            icon={
              <FontAwesomeIcon icon={faSave} style={{ fontSize: '16px' }} />
            }
            onClick={handleSaveBooking}
          >
            Save
          </StyledSubmitButton>
        </Col>
      </Row>
    );
  };
  return (
    <Modal
      title="Update Port info"
      width={500}
      visible={visible}
      onClose={handleClose}
      footer={false}
    >
      {renderBody()}
    </Modal>
  );
}

export default UpDatePortInfo;
