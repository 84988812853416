import { faClose, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoComplete, Input } from 'antd';
import {
  CountryCodeTypeEnum,
  useGetMapAddressesLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import Spinner from 'components/Loaders/Spinner';
import usePlacesStore from 'containers/Locations/Places/DetailsScreen/store';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { Control } from 'react-hook-form';
import { LatitudAndLongitudValidator } from 'helpers/LatitudAndLongitudValidator';

type Props = Readonly<{
  control: Control;
  name?: string;
}>;

export default function FormControlledMapAddressAutoComplete({
  name = 'organizationId',
  control,
}: Props) {
  const [options, setOptions] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const setSelectedPlace = usePlacesStore(state => state.setSelectedPlace);

  const [getMapAddesses, { loading }] = useGetMapAddressesLazyQuery({
    onCompleted: (response: any) => {
      const {
        mapAddress: { nodes },
      } = response;

      if (nodes?.length) {
        const options = nodes.map((data: any) => {
          return {
            value: `${data?.placeName}`,
            key: data.mapId,
          };
        });

        setAddresses(nodes);
        setOptions(options);
      }
    },
  });

  const _debouncedUpdate = (value: string = '') => {
    if (!value) {
      setOptions([]);
      return false;
    }

    const variables = {
      input: value,
      countryCode: CountryCodeTypeEnum.Nz,
      filters: null,
    };

    getMapAddesses({
      variables,
    });
    return true;
  };

  function _onSelect(_val, option) {
    const selectedItem = addresses?.find(a => a.mapId === option.key);

    if (selectedItem) {
      const selectedLocality =
        selectedItem?.placeType === 'locality'
          ? selectedItem?.text
          : selectedItem?.locality;
      const selectedPlace =
        selectedItem?.placeType === 'place'
          ? selectedItem?.text
          : selectedItem?.place;
      const address1 = selectedItem?.address
        ? `${selectedItem?.address} ${selectedItem?.text}`
        : selectedItem?.text;
      selectedItem.address = address1;

      const populateFields = {
        address1,
        placeName: selectedItem?.placeName,
        postCode: selectedItem?.postCode,
        region: selectedItem?.region,
        regionCode: selectedItem?.regionCode,
        mapId: selectedItem?.mapId,
        coordinates: selectedItem?.coordinates,
        longitude: LatitudAndLongitudValidator(
          'longitude',
          selectedItem?.longitude,
        ),
        latitude: LatitudAndLongitudValidator(
          'latitude',
          selectedItem?.latitude,
        ),
        locality: selectedLocality,
        country: selectedItem?.country,
        countryCode: selectedItem?.countryCode,
        place: selectedPlace,
        placeType: selectedItem?.placeType,
        neighborhood: selectedItem?.neighborhood,
        district: selectedItem?.district,
      };
      setSelectedPlace(populateFields);
    }
  }

  function _onClear() {
    setSelectedPlace(null);
  }

  return (
    <WithFormHooksController
      control={control}
      name={name}
      component={
        <AutoComplete
          allowClear
          clearIcon={<FontAwesomeIcon icon={faClose} />}
          options={options}
          onSearch={debounce(_debouncedUpdate, 400)}
          onSelect={_onSelect}
          onClear={_onClear}
          filterOption={(input, option) =>
            option?.value
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          <Input
            className="px-2 rounded-md"
            placeholder="search location address"
            prefix={
              loading ? (
                <span>
                  <Spinner />
                </span>
              ) : (
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={{
                    fontSize: '12px',
                    color: 'gray',
                    marginRight: '4px',
                    opacity: 0.3,
                  }}
                />
              )
            }
          />
        </AutoComplete>
      }
    />
  );
}
