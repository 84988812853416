export const dateFilterParams = {
  minValidYear: 2022,
  filterOptions: [
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'greaterThan',
      displayName: 'Greater Than',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'lessThan',
      displayName: 'Less Than',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'blank',
      displayName: 'Empty',
      predicate: ([_filterValue], _cellValue) => 1,
      numberOfInputs: 0,
    },
    {
      displayKey: 'notBlank',
      displayName: 'Not Empty',
      predicate: ([_filterValue], _cellValue) => 1,
      numberOfInputs: 0,
    },
  ],
};

export const textFilterParams = {
  debounceMs: 1000,
  refreshValuesOnOpen: true,
  newRowsAction: 'keep',
  filterOptions: [
    {
      displayKey: 'contains',
      displayName: 'Contains',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'notContains',
      displayName: 'Not Contains',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'notEqual',
      displayName: 'Not Equal',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'startsWith',
      displayName: 'Starts With',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'endsWith',
      displayName: 'Ends With',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'blank',
      displayName: 'Empty',
      predicate: ([_filterValue], _cellValue) => 1,
      numberOfInputs: 0,
    },
    {
      displayKey: 'notBlank',
      displayName: 'Not Empty',
      predicate: ([_filterValue], _cellValue) => 1,
      numberOfInputs: 0,
    },
  ],
};

export const numberFilterParams = {
  defaultJoinOperator: 'OR',
  alwaysShowBothConditions: true,

  debounceMs: 1000,
  filterOptions: [
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'notEqual',
      displayName: 'Not Equal',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'greaterThan',
      displayName: 'Greater Than',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'lessThan',
      displayName: 'Less Than',
      predicate: ([_filterValue], _cellValue) => 1,
    },
    {
      displayKey: 'blank',
      displayName: 'Empty',
      predicate: ([_filterValue], _cellValue) => 1,
      numberOfInputs: 0,
    },
    {
      displayKey: 'notBlank',
      displayName: 'Not Empty',
      predicate: ([_filterValue], _cellValue) => 1,
      numberOfInputs: 0,
    },
  ],
};
export const selectorFilterParams = {
  debounceMs: 1000,
  newRowsAction: 'keep',
  suppressAndOrCondition: true,
  filterOptions: [
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: () => 1,
    },
  ],
};

export const booleanSelectorFilterParams = {
  debounceMs: 1000,
  newRowsAction: 'keep',
  suppressAndOrCondition: true,
  filterOptions: [
    {
      displayKey: 'equals',
      displayName: 'Equals',
      predicate: () => 1,
    },
    {
      displayKey: 'notEqual',
      displayName: 'not Equal',
      predicate: () => 1,
    },
  ],
};
