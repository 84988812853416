import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'antd';
import {
  CountryCodeTypeEnum,
  useGetMapAddressesLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { actions } from 'context/financials_rate/slice';
import { LatitudAndLongitudValidator } from 'helpers/LatitudAndLongitudValidator';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

interface ISelectCellParams extends ICellEditorParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  // = ============================= init ===========================//
  const [value, setValue] = useState(props.value);

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [placeSearch, setPlaceSearch] = useState(null);
  const [suggestList, setSuggestList] = useState(null);

  const typingTimeoutRef = useRef(null);

  const dispatch = useDispatch();

  // = ============================= hanlder ===========================//

  const handleSearch = value => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setPlaceSearch(value);
    }, 300);
  };

  const refineAddressData = data => {
    const selectedLocality =
      data?.placeType === 'locality' ? data?.text : data?.locality;
    const selectedPostCode =
      data?.placeType === 'postcode' ? data?.text : data?.postCode;
    const selectedPlace =
      data?.placeType === 'place' ? data?.text : data?.place;
    const selectedCountry =
      data?.placeType === 'country' ? data?.text : data?.country;

    return {
      address1: data?.address,
      postCode: selectedPostCode,
      region: data?.region,
      regionCode: data?.regionCode,
      mapId: data?.mapId,
      coordinates: data?.coordinates,
      longitude: LatitudAndLongitudValidator('longitude', data?.longitude),
      latitude: LatitudAndLongitudValidator('latitude', data?.latitude),
      locality: selectedLocality,
      country: selectedCountry,
      countryCode: data?.countryCode,
      place: selectedPlace,
      placeType: data?.placeType,
      neighborhood: data?.neighborhood,
      district: data?.district,
    };
  };

  const handleSelectPlaceChange = value => {
    if (value) {
      const selectedItem = suggestList?.find(item => item?.mapId === value);
      setSelectedPlace(selectedItem);
      const populateFields = refineAddressData(selectedItem);

      dispatch(
        actions.setAddressFrom({
          addressFrom: populateFields,
        }),
      );
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const valueFormat = suggestList?.find(item => item.mapId === value);
        if (valueFormat) {
          return refineAddressData(valueFormat);
        }
        return props.value;
      },

      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  const handleClear = () => {
    setSelectedPlace(null);
  };

  // = ============================= data processing ===========================//

  const [
    getMapAddressQuery,
    { loading: pendingAddress },
  ] = useGetMapAddressesLazyQuery({
    onCompleted: response => {
      if (response?.mapAddress?.nodes) {
        const nodes = response.mapAddress.nodes.map(dataItem => {
          if (dataItem.mapId) {
            return dataItem;
          }
          return undefined;
        });
        const filterList = ['place', 'postcode', 'locality', 'country'];
        const filteredNodes = nodes?.filter(item =>
          filterList.find(el => el === item?.placeType),
        );
        setSuggestList(filteredNodes);
      }
    },
  });

  // = ============================= useEffect ===========================//

  useEffect(() => {
    if (placeSearch?.length > 0) {
      getMapAddressQuery({
        variables: {
          input: placeSearch,
          countryCode: CountryCodeTypeEnum.Nz,
          filters: null,
        },
      });
    }
  }, [placeSearch]);

  // = ============================= render components ===========================//

  const renderSearch = () => {
    const fullAddress = selectedPlace?.address1
      ? `${selectedPlace?.address1},
      ${selectedPlace?.locality},
      ${selectedPlace?.region}
      ${selectedPlace?.postCode},
      ${selectedPlace?.country}`
      : selectedPlace?.placeName;

    return (
      <Select
        style={{ width: '100%' }}
        bordered={false}
        showSearch
        allowClear
        onClear={handleClear}
        loading={pendingAddress}
        value={selectedPlace ? fullAddress : null}
        //   defaultValue={defaultValue}
        defaultActiveFirstOption={false}
        placeholder={'search address'}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleSelectPlaceChange}
        notFoundContent={null}
      >
        {suggestList?.map(item => (
          <Select.Option key={item?.mapId} value={item?.mapId} idName="abc">
            {item?.placeName}
          </Select.Option>
        ))}
      </Select>
    );
  };

  // = ============================= main render ===========================//

  return <>{renderSearch()}</>;
});
