export const CustomAttributeName = [
  { label: 'FreightPalletHire', value: 'freightPalletHire' },
  { label: 'ContainerJobType', value: 'containerJobType' },
  { label: 'ContainerNumber', value: 'containerNumber' },
  { label: 'ContainerFullEmpty', value: 'containerFullEmpty' },
  { label: 'ContainerOwner', value: 'containerOwner' },
  { label: 'ContainerSize', value: 'containerSize' },
  { label: 'ContainerType', value: 'containerType' },
  { label: 'ContainerVesselName', value: 'containerVesselName' },
  { label: 'ContainerVoyageNo', value: 'containerVoyageNo' },
  { label: 'ContainerReleaseNo', value: 'containerReleaseNo' },
  { label: 'ContainerPinNo', value: 'containerPinNo' },
  { label: 'ContainerRandom', value: 'containerRandom' },
  { label: 'ContainerSealNo', value: 'containerSealNo' },
  { label: 'ContainerDoorSide', value: 'containerDoorSide' },
  { label: 'ContainerLoadType', value: 'containerLoadType' },
  { label: 'containerLastFreeTime', value: 'containerLastFreeTime' },
  { label: 'ContainerDetentionDate', value: 'containerDetentionDate' },
  { label: 'ContainerIsoTypeCode', value: 'containerIsoTypeCode' },
  { label: 'ContainerSlotDate', value: 'containerSlotDate' },
  { label: 'ContainerSlotReference', value: 'containerSlotReference' },
  { label: 'ContainerVBSFull', value: 'containerVBSFull' },
  { label: 'ContainerDischargedDate', value: 'containerDischargedDate' },
  { label: 'ContainerPortCode', value: 'containerPortCode' },
  { label: 'SEAL', value: 'containerSeal' },
  { label: 'RCS', value: 'containerRCS' },
  { label: 'RMP', value: 'containerRMP' },
  { label: 'SES', value: 'containerSES' },
  { label: 'MPI', value: 'containerMPI' },
  { label: 'ContainerAPNumber', value: 'containerAPNumber' },
  { label: 'PalletReturn', value: 'freightPalletReturn' },
  { label: 'FreightLifts', value: 'freightLifts' },
];
