import { AddBookingChargeInput } from 'api/graphql/generated/serviceTypesAndHooks';
import { create } from 'zustand';

interface IState {
  selectedSurcharge:
    | (Partial<AddBookingChargeInput> & { isSaving: boolean })
    | null;
}

interface IActions {
  setSelectedSurcharge: (surcharge: Partial<AddBookingChargeInput>) => void;
  setIsSaving: (status: boolean) => void;
  reset: () => void;
}

const useSurchargesStore = create<IState & IActions>((set, _get) => ({
  selectedSurcharge: null,
  setSelectedSurcharge: (surcharge: any) =>
    set({ selectedSurcharge: surcharge }),
  setIsSaving: (status: boolean) =>
    set(state => ({
      selectedSurcharge: {
        ...state.selectedSurcharge,
        isSaving: status,
      },
    })),
  reset: () =>
    set({
      selectedSurcharge: null,
    }),
}));

export default useSurchargesStore;
