import { getDateColorRules } from 'components/ReactGrid/renderers/styleFormatter';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import { sortByNumber } from './comparators';
import { dateCalculateRule } from './date';
import { dateFilterParams, numberFilterParams } from './filterParams';

const colDef = [
  {
    headerName: 'Container  Number',
    colId: 'containerNumber',
    field: 'containerNumber',
    width: 140,
  },
  {
    headerName: 'Cont Full/Empty',
    colId: 'containerFullEmpty',
    field: 'containerFullEmpty',
    width: 140,
  },
  {
    headerName: 'Container  Owner',
    colId: 'containerOwner',
    field: 'containerOwner',
    width: 140,
  },
  {
    headerName: 'Size',
    colId: 'containerSize',
    field: 'containerSize',
    width: 140,
    cellRendererParams: {
      type: 'number',
    } as ICustomCellRendererParams,
    filterParams: numberFilterParams,
    comparator: sortByNumber,
  },
  {
    headerName: 'Type',
    colId: 'containerType',
    field: 'containerType',
    width: 140,
  },
  {
    headerName: 'Vessel',
    colId: 'containerVesselName',
    field: 'containerVesselName',
    width: 140,
  },
  {
    headerName: 'Voyage',
    colId: 'containerVoyageNo',
    field: 'containerVoyageNo',
    width: 140,
  },
  {
    headerName: 'Release',
    colId: 'containerReleaseNo',
    field: 'containerReleaseNo',
    width: 140,
  },
  {
    headerName: 'Pin',
    colId: 'containerPinNo',
    field: 'containerPinNo',
    width: 140,
  },
  {
    headerName: 'Random',
    colId: 'containerRandom',
    field: 'containerRandom',
    width: 140,
  },
  {
    headerName: 'Seal',
    colId: 'containerSealNo',
    field: 'containerSealNo',
    width: 140,
  },
  {
    headerName: 'Doors',
    colId: 'containerDoorSide',
    field: 'containerDoorSide',
    width: 140,
  },
  {
    headerName: 'Last Free Time',
    colId: 'containerLastFreeTime',
    field: 'containerLastFreeTime',
    width: 180,
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams,
    valueGetter: params => {
      if (params?.data?.containerLastFreeTime) {
        return dateCalculateRule(params?.data?.containerLastFreeTime);
      }
      return null;
    },
    cellClassRules: getDateColorRules(4, 2),
  },
  {
    headerName: 'Detention',
    colId: 'containerDetentionDate',
    field: 'containerDetentionDate',
    width: 180,
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams,
    valueGetter: params => {
      if (params?.data?.containerDetentionDate) {
        return dateCalculateRule(params?.data?.containerDetentionDate);
      }
      return null;
    },
    cellClassRules: getDateColorRules(5, 3),
  },
  {
    headerName: 'IsoType',
    colId: 'containerIsoTypeCode',
    field: 'containerIsoTypeCode',
    width: 140,
  },
  {
    headerName: 'Cont Slot Date',
    colId: 'containerSlotDate',
    field: 'containerSlotDate',
    width: 180,
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams,
    cellRendererParams: {
      type: 'datetime',
    } as ICustomCellRendererParams,
  },
  {
    headerName: 'Cont Slot Reference',
    colId: 'containerSlotReference',
    field: 'containerSlotReference',
    width: 140,
  },
  {
    headerName: 'Cont Discharged Date',
    colId: 'containerDischargedDate',
    field: 'containerDischargedDate',
    width: 180,
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams,
    cellRendererParams: {
      type: 'datetime',
    } as ICustomCellRendererParams,
  },
  {
    headerName: 'Port Code',
    colId: 'containerPortCode',
    field: 'containerPortCode',
    width: 140,
  },
  {
    headerName: 'SEAL',
    colId: 'containerSEAL',
    field: 'containerSEAL',
    width: 140,
    cellRenderer: 'DualCheckedCell',
    valueGetter: params => {
      if (params?.data?.containerSeal) {
        const value = params.data.containerSeal;
        if (value?.includes('|')) {
          const dualValues = value?.split('|');
          return {
            webChecked: dualValues[0] === 'true',
            mobileChecked: dualValues[1] === 'true',
          };
        }
        return {
          webChecked: value === 'true',
        };
      }
      return null;
    },
  },
  {
    headerName: 'RCS',
    colId: 'RCS',
    field: 'containerRCS',
    width: 140,
    cellRenderer: 'DualCheckedCell',
    valueGetter: params => {
      if (params?.data?.containerRCS) {
        const value = params.data.containerRCS;
        if (value?.includes('|')) {
          const dualValues = value?.split('|');
          return {
            webChecked: dualValues[0] === 'true',
            mobileChecked: dualValues[1] === 'true',
          };
        }
        return {
          webChecked: value === 'true',
        };
      }
      return null;
    },
  },
  {
    headerName: 'RMP',
    colId: 'containerRMP',
    field: 'containerRMP',
    width: 140,
    cellRenderer: 'DualCheckedCell',
    valueGetter: params => {
      if (params?.data?.containerRMP) {
        const value = params.data.containerRMP;
        if (value?.includes('|')) {
          const dualValues = value?.split('|');
          return {
            webChecked: dualValues[0] === 'true',
            mobileChecked: dualValues[1] === 'true',
          };
        }
        return {
          webChecked: value === 'true',
        };
      }
      return null;
    },
  },
  {
    headerName: 'SES',
    colId: 'containerSES',
    field: 'containerSES',
    width: 140,
    cellRenderer: 'DualCheckedCell',
    valueGetter: params => {
      if (params?.data?.containerSES) {
        const value = params.data.containerSES;
        if (value?.includes('|')) {
          const dualValues = value?.split('|');
          return {
            webChecked: dualValues[0] === 'true',
            mobileChecked: dualValues[1] === 'true',
          };
        }
        return {
          webChecked: value === 'true',
        };
      }
      return null;
    },
  },
  {
    headerName: 'MPI',
    colId: 'containerMPI',
    field: 'containerMPI',
    width: 140,
    cellRenderer: 'DualCheckedCell',
    valueGetter: params => {
      if (params?.data?.containerMPI) {
        const value = params.data.containerMPI;
        if (value?.includes('|')) {
          const dualValues = value?.split('|');
          return {
            webChecked: dualValues[0] === 'true',
            mobileChecked: dualValues[1] === 'true',
          };
        }
        return {
          webChecked: value === 'true',
        };
      }
      return null;
    },
  },
  {
    headerName: 'AP Number',
    colId: 'containerAPNumber',
    field: 'containerAPNumber',
    width: 140,
  },
];

export default colDef;
