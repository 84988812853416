import { GridApi } from 'ag-grid-community';
import { TBookingPlaceTypes } from 'components/Workspace/defaultConfigs/types/booking';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function getNextMilestoneDate(placeType: TBookingPlaceTypes) {
  return placeType === TBookingPlaceTypes.Delivery
    ? dayjs(Date.now()).add(7, 'day').utc().toISOString()
    : dayjs(Date.now()).utc().toISOString();
}

export function getInitRowData(
  placeType: TBookingPlaceTypes,
  bookingNextMilestoneType: number,
) {
  return [
    {
      bookingNextMilestoneType,
      nextMilestone: getNextMilestoneDate(placeType),
    },
  ];
}

export function getGridData(api: GridApi) {
  const items = [];
  const rowData = [];

  api.forEachNode(node => {
    const propsCount = Object.keys(node.data).length;
    let item = {};

    for (let i = 0; i < propsCount; i++) {
      item = {
        ...item,
        [Object.keys(node.data)[i]]: node.data[Object.keys(node.data)[i]],
      };
    }

    items.push(item);
    rowData.push(node.data);
  });

  return {
    items,
    rowData,
  };
}
